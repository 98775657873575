import {
	BookingSelect,
	BookingSelectInput,
	BookingSelectOption,
	Button,
	Grid,
	useBookingSelectState,
	Alert,
} from '@keahotels/components';
import { useCompositeState, Composite } from 'reakit/Composite';
import useTranslation from 'next-translate/useTranslation';
import React, { useState } from 'react';
import { formatISO9075, format } from 'date-fns';

import { ReactComponent as NorthEast } from '@keahotels/assets/icons/NorthEast.svg';

import useGlobalState, { useGetCurrency } from '../../hooks/useGlobalState';

import styles from './BlockBookOffer.module.css';
import GuestsPicker from '../AppDatePicker/GuestsPicker';
import useAddToCartMutation from './../BookingSearchResults/useAddToCartMutation';
import useFormatCurrency from '../../hooks/useFormatCurrency';
import { Currency, Language } from '../../../__generated__/globalTypes';
import { useQuery } from '@apollo/client';
import {
	GetAvailableRooms,
	GetAvailableRoomsVariables,
	GetAvailableRooms_Availability_results,
} from '../BookingSearchResults/__generated__/GetAvailableRooms';

import c from 'classnames';
import { BookingSearchQuery } from '../BookingSearchResults/useBookingSearch';
import { useSettings } from '../AppLayout/AppLayout';
import { useRouter } from 'next/router';
import client from '../../../apollo-client';

import { flatten } from 'lodash';
import { isNotNullOrUndefined } from '../../utils/isNotNull';

export type BlockBookOfferProps = {
	price?: number;
	currency?: string;
	rateId?: number | null;
	dates?: {
		id: string;
		label: string;
		value: string | number;
		checkIn: Date;
		checkOut: Date;
		availability: number;
	}[];
	roomTypes?: {
		label: string;
		value: string | number;
		godoId: string | number;
	}[];
	bookingKey?: string;
	bonSourceId: number | null;
};

export default function BlockBookOffer({
	dates = [],
	roomTypes = [],
	bonSourceId,
}: BlockBookOfferProps) {
	const { t } = useTranslation('common');
	const { state } = useGlobalState();
	const { hotelSettings } = useSettings();
	const { locale = 'en' } = useRouter();
	const lang: Language = Language[locale.toUpperCase()] || Language.EN;

	const [selectedDateValue, setSelectedDateValue] = useState<
		string | number | undefined
	>(
		// dates[firstDateWithAvailability].value
		undefined,
	);

	const [selectedRooms, setSelectedRooms] =
		useState<GetAvailableRooms_Availability_results | null>(null);

	const stateCurrency = useGetCurrency();

	const selectedDate = dates.find((o) => o.id === selectedDateValue);

	const { data } = useQuery<GetAvailableRooms, GetAvailableRoomsVariables>(
		BookingSearchQuery,
		{
			skip: !hotelSettings?.bonId || !dates.length || !selectedDate,
			variables: {
				hotels: hotelSettings?.bonId
					? [hotelSettings?.bonId.toString()]
					: null,
				from: selectedDate
					? format(selectedDate.checkIn, 'yyyy-MM-dd')
					: '',
				to: selectedDate
					? format(selectedDate.checkOut, 'yyyy-MM-dd')
					: '',
				rooms: state.rooms,
				currency: stateCurrency,
				lang,
				offerKey: String(selectedDateValue) || '',
				offer: {
					src: bonSourceId || 0,
					userCountry: 'IS',
				},
			},

			client: client,
		},
	);

	// const { data: RoomsAvailabilityData } = useQuery<
	// 	AvailabilityByRooms,
	// 	AvailabilityByRoomsVariables
	// >(RoomsAvailabilityCheckQuery, {
	// 	variables: {
	// 		hotelId: hotelSettings?.godoId?.toString() || '',
	// 		dateFrom: selectedDate
	// 			? format(selectedDate.checkIn, 'yyyy-MM-dd')
	// 			: '',
	// 		dateTo: selectedDate
	// 			? format(selectedDate.checkOut, 'yyyy-MM-dd')
	// 			: '',
	// 		rooms: roomTypes.map((item) => String(item.godoId)),
	// 	},
	// 	client: client,
	// });

	const results = data?.Availability?.results;

	const price =
		results &&
		selectedRooms?.rooms
			// .map((item) => item?.rates[0]?.price || 0)
			.map(
				(item) =>
					item?.rates[0]?.availabilityCalendar?.filter(
						(item) =>
							item.date ===
							(selectedDate
								? format(selectedDate.checkIn, 'yyyy-MM-dd')
								: ''),
					)[0].priceWithDiscount || 0,
			)
			.reduce((total, val) => Number(total || 0) + Number(val || 0));

	const currency = (results && results[0]?.rooms[0].rates[0]?.currency) || '';

	const f = useFormatCurrency({
		currency: Currency[currency],
		maximumFractionDigits: stateCurrency === Currency.ISK ? 0 : 2,
	});

	const datesController = useBookingSelectState({
		placement: 'bottom-start',
		modal: false,
	});
	const roomsController = useBookingSelectState({
		placement: 'bottom-start',
		modal: false,
	});
	const guestsController = useBookingSelectState({
		placement: 'bottom-start',
		modal: false,
		gutter: -2,
	});
	const datesComposite = useCompositeState({ unstable_virtual: true });
	const roomsComposite = useCompositeState({ unstable_virtual: true });

	const totalGuests = state.rooms.reduce(
		(total, { adults, children, infants }) => {
			return total + adults + children + infants;
		},
		0,
	);
	const hasChildren = state.rooms.some((r) => !!(r.children + r.infants));

	const [addToCart, { called, error }] = useAddToCartMutation();

	const selectedRates = flatten(
		selectedRooms?.rooms.map((r) => r.rates.map((rr) => rr?.rateId)),
	).filter(isNotNullOrUndefined);
	const selectedBookingKey = selectedRooms?.bookingKey;

	return (
		<Grid>
			<Grid className={styles.grid}>
				<div className={styles.fieldsWrap}>
					<BookingSelect
						buttonLabel={t('selectDatesButtonLabel')}
						controller={datesController}
						asListbox
						value={
							<div className={styles.bookingSelectValue}>
								<BookingSelectInput
									label={t('dates')}
									value={
										selectedDate?.label || t('selectDates')
									}
								/>
							</div>
						}
					>
						<Composite
							{...datesComposite}
							role="none"
							as="ul"
							style={{ padding: 0, margin: 0 }}
						>
							{dates.map(({ label, value, availability, id }) => {
								const hasAvailability =
									availability && availability >= totalGuests;

								return (
									<BookingSelectOption
										key={`${value}__${label}`}
										controller={datesComposite}
										aria-selected={
											selectedDateValue === value
										}
										aria-disabled={!hasAvailability}
										onClick={() =>
											setSelectedDateValue(value)
										}
										className={c({
											[styles.option]: true,
										})}
									>
										{label}
										{!hasAvailability && (
											<span className={styles.soldout}>
												{availability
													? `Aðeins ${availability} laus pláss`
													: t('noAvailability')}
											</span>
										)}
									</BookingSelectOption>
								);
							})}
						</Composite>
					</BookingSelect>
					{selectedDateValue && (
						<BookingSelect
							buttonLabel={t('selectRoomType')}
							controller={roomsController}
							asListbox
							className={c({
								[styles.selectRoomType]: true,
								[styles.loading]: !results,
							})}
							value={
								<div className={styles.bookingSelectValue}>
									<BookingSelectInput
										label={t('roomType')}
										value={
											selectedRooms?.title || t('select')
										}
									/>
								</div>
							}
						>
							<Composite
								{...roomsComposite}
								role="none"
								as="ul"
								style={{ padding: 0, margin: 0 }}
							>
								{results?.map((item) => {
									return (
										<BookingSelectOption
											key={`${item?.title}_${item?.rooms[0].roomId}__${item?.rooms[0].title}`}
											controller={roomsComposite}
											aria-selected={
												selectedRooms === item
											}
											className={c({
												[styles.option]: true,
											})}
											// aria-disabled={!hasAvailability}
											onClick={() =>
												setSelectedRooms(item)
											}
										>
											{item?.title}
										</BookingSelectOption>
									);
								})}
							</Composite>
						</BookingSelect>
					)}
					<BookingSelect
						buttonLabel={t('select-guests')}
						controller={guestsController}
						value={
							<div className={styles.bookingSelectValue}>
								<BookingSelectInput
									label={t('Guests')}
									value={
										hasChildren
											? `${totalGuests} ${t(
													'guestsValuePlural',
											  )}`
											: `${totalGuests} ${t(
													totalGuests === 1
														? 'adultsValueSingular'
														: 'adultsValuePlural',
											  )}`
									}
								/>
							</div>
						}
					>
						<GuestsPicker
						// maxAdults={2}
						// maxChildren={1}
						// maxInfants={1}
						/>
					</BookingSelect>
					{selectedRooms && selectedDateValue && (
						<>
							<div className={styles.priceWrap}>
								<div
									className={c({
										[styles.price]: true,
										[styles.loading]: !price,
									})}
								>
									<BookingSelectInput
										label={t('price')}
										value={
											<span>
												{price
													? f.format(price)
													: f.format(0)}
											</span>
										}
										underline={false}
									/>
								</div>
							</div>
						</>
					)}
					<Button
						className={c({
							[styles.submit]: true,
						})}
						icon={<NorthEast />}
						disabled={
							(!error && called) ||
							!price ||
							!selectedRooms ||
							!selectedDateValue
						}
						onClick={(e) => {
							// console.log('adding to Cart');

							addToCart({
								variables: {
									key: selectedBookingKey || '',
									offerKey: String(selectedDateValue) || '',
									checkOut: selectedDate?.checkOut
										? formatISO9075(selectedDate.checkOut, {
												representation: 'date',
										  })
										: '',
									checkIn: selectedDate?.checkIn
										? formatISO9075(selectedDate.checkIn, {
												representation: 'date',
										  })
										: '',
									rateIds: selectedRates,
								},
							}).then();
						}}
					>
						{!selectedDateValue
							? t('selectDates')
							: !selectedRooms
							? t('selectRoomType')
							: called
							? t('paymentSubmitting')
							: t('bookNow')}
					</Button>
				</div>

				{results && !results.length && (
					<Alert className={styles.alert}>
						{t('errorNoRoomsFound')}
					</Alert>
				)}
			</Grid>
		</Grid>
	);
}
