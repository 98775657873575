import { gql, MutationTuple, useMutation } from '@apollo/client';
import useGlobalState from '../../hooks/useGlobalState';
import { useSettings } from '../AppLayout/AppLayout';
import router from 'next/router';

import { CartFragment } from './../../templates/PaymentPage/PaymentPage';

import {
	SetCartMutation,
	SetCartMutationVariables,
} from './__generated__/SetCartMutation';
import client from '../../../apollo-client';
import hotelData from '../../utils/hotelData';

const SetCartMutationQuery = gql`
	mutation SetCartMutation(
		$key: String!
		$rateIds: [Int]
		$checkIn: String!
		$checkOut: String!
		$offerKey: String
	) {
		SetCart(
			bookingKey: $key
			rateIds: $rateIds
			checkIn: $checkIn
			checkOut: $checkOut
			offerKey: $offerKey
		) {
			success
			errors {
				key
				message
			}
			cart {
				...CartFragment
			}
		}
	}
	${CartFragment}
`;

export default function useAddToCartMutation() {
	const { createBooking, state } = useGlobalState();
	const { hotelSettings } = useSettings();

	const hotelSlug = hotelSettings?.uri.replace(/\/$/, '');
	const googlePlaceId = hotelSettings?.originalId
		? hotelData[hotelSettings?.originalId]?.googlePlaceId
		: undefined;

	return useMutation<SetCartMutation, SetCartMutationVariables>(
		SetCartMutationQuery,
		{
			client: client,
			onCompleted(data) {
				if (data?.SetCart && data.SetCart.success) {
					if (data.SetCart.cart) {
						createBooking(data.SetCart.cart);
						const cart = data.SetCart.cart;
						const rooms = cart.rooms;

						const gtagData = {
							currency: cart.currency,
							value: cart.totalAmount,
							coupon: state.promoCode,
							affiliation: 'Website',
							items: rooms?.map((r) => ({
								item_id: `${r.roomId}__${r.rate.rateId}`,
								item_name: r.title,
								location_id: googlePlaceId,
								adults: r.adults,
								children: r.children,
								infants: r.infants,
								price: r.rate.price,
								discount: r.rate.discount?.amount || 0,
								item_brand: hotelSettings?.title, // Hotel name
								item_category: r.rate.cancelation?.cancelBefore
									? 'Refundable'
									: 'Non-refundable', // ref/non ref
								quantity: 1,
							})),
						};

						gtag('event', 'begin_checkout', gtagData);
					}

					router.push(`${hotelSlug || ''}/book/payment`);
				} else {
					throw new Error(data.SetCart.errors?.[0]?.key);
				}
			},
			onError: (err) => {
				console.log('err', err);
			},
		},
	);
}
