import { gql } from '@apollo/client';
import client from '../../apollo-client';
import { ImageFragment } from '../components/Image/Image';
import { TranslationFragment } from '../queries/GetPage';
import { HotelSettingsFragment } from './useHotelSettings';
import {
	GetGlobalData,
	GetGlobalDataVariables,
} from './__generated__/GetGlobalData';

export const GlobalSettingsFragment = gql`
	fragment GlobalSettingsFragment on Query {
		globalSettings: WpGlobalInfo(locale: $locale) {
			hotels {
				title
				items {
					id
					title
					uri
				}
			}
			reg
			headOfficeAddress
			salesOfficeAddress
			phone
			fax
			email
			tripAdvisorUrl
			facebookUrl
			twitterUrl
			instagramUrl
			privacyPolicy
		}
	}
`;

const GetGlobalDataQuery = gql`
	query GetGlobalData($hotel: String!, $locale: String!) {
		hotelSettings: WpItem(slug: $hotel, locale: $locale) {
			...HotelSettingsFragment
		}
		...GlobalSettingsFragment
	}
	${HotelSettingsFragment}
	${GlobalSettingsFragment}
`;

export default async function getGlobalData({
	locale,
	hotel = '',
}: {
	locale: string;
	hotel?: string;
}) {
	return client.query<GetGlobalData, GetGlobalDataVariables>({
		query: GetGlobalDataQuery,
		variables: {
			hotel,
			locale,
		},
	});
	// .catch((e) => {
	// 	console.error(e);
	// 	console.error(e.networkError);
	// 	console.error('Errors');
	// 	if (
	// 		e?.networkError?.result?.errors &&
	// 		e?.networkError.result.errors.length
	// 	) {
	// 		e?.networkError.result.errors.map((err: any) => {
	// 			console.log(err);
	// 			console.log(err.extensions.exception);
	// 		});
	// 	}
	// 	return { data: { page: null } };
	// });
}
