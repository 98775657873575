import NextImage, { ImageProps as NextImageProps } from 'next/image';
import c from 'classnames';
import styles from './Image.module.css';
import { ReactNode } from 'react';
import gql from 'graphql-tag';
import HotelLogo from '../HotelLogo/HotelLogo';

export const aspectMultiplier = {
	square: 1,
	portrait: 1.6,
	landscape: 0.625,
	'header-landscape': 0.5089820359,
};

export type CommonImageProps = {
	aspectRatio: 'square' | 'portrait' | 'landscape' | 'header-landscape';
	ctaBox?: ReactNode;
	hotelOverlay?: number;
	overlaySize?: 'small' | 'medium';
	caption?: ReactNode;
	captionPosition?: 'outside' | 'inside' | 'none';
};

export type ImageProps =
	| (CommonImageProps &
			({
				src: string;
				url?: undefined;
				id?: any;
			} & NextImageProps))
	| (CommonImageProps &
			({
				url: string;
			} & Omit<NextImageProps, 'src'>));

export default function Image({
	className = '',
	height,
	width,
	layout,
	ctaBox,
	aspectRatio = 'square',
	hotelOverlay,
	overlaySize = 'medium',
	url,
	caption = null,
	captionPosition = 'none',
	id,
	// @ts-ignore - for some reason TS doesn't pick up src
	src,
	alt = '',
	...rest
}: ImageProps) {
	const ll =
		layout ||
		(typeof height === 'undefined' && typeof width === 'undefined')
			? 'fill'
			: undefined;

	let h = typeof height === 'string' ? parseInt(height) : height;
	let w = typeof width === 'string' ? parseInt(width) : width;

	if (h && !w) w = h / aspectMultiplier[aspectRatio];
	if (w && !h) h = w * aspectMultiplier[aspectRatio];

	return (
		<div
			className={c({
				[styles.wrap]: 1,
				[styles[aspectRatio]]: 1,
				[className]: className,
			})}
		>
			{(url || src) && (
				<NextImage
					className={styles.img}
					layout={ll}
					height={h}
					width={w}
					alt={alt}
					src={
						url
							? `${process.env.NEXT_PUBLIC_IMAGE_URL}/${url}`
							: src
					}
					{...rest}
				/>
			)}

			{hotelOverlay && (
				<div
					className={`${styles.hotelOverlay} ${
						overlaySize === 'small' ? styles.small : ''
					} blurryBackdrop`}
				>
					{[9879].includes(hotelOverlay) ? (
						<img src="/grimsborgir-logo-2.png" alt="" />
					) : (
						<HotelLogo id={hotelOverlay} />
					)}
				</div>
			)}
			{captionPosition !== 'none' && (
				<div
					className={`${styles.caption} ${styles[captionPosition]} blurryBackdrop`}
				>
					{caption}
				</div>
			)}
			{ctaBox && <div className={styles.ctaBox}>{ctaBox}</div>}
		</div>
	);
}

export const ImageFragment = gql`
	fragment ImageFragment on Image {
		url
		caption
		alt
		width
		height
	}
`;
