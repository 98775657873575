import React, {
	useEffect,
	useReducer,
	useState,
	useCallback,
	useRef,
	Fragment,
	useMemo,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { VisuallyHidden } from 'reakit/VisuallyHidden';
import { useLazyQuery, gql, useMutation } from '@apollo/client';
import { eachYearOfInterval, addYears } from 'date-fns';

import {
	Aside,
	Button,
	Eyebrow,
	Form,
	FormInput,
	FormCheckbox,
	Grid,
	Section,
	SectionHeading,
	Modal,
	useModalState,
	FormSelect,
	Alert,
	ModalDisclosure,
} from '@keahotels/components';

import { ReactComponent as NorthEast } from '@keahotels/assets/icons/NorthEast.svg';

import BookingAside from '../../components/BookingAside/BookingAside';

import { useSettings } from '../../components/AppLayout/AppLayout';
import useGlobalState from '../../hooks/useGlobalState';
import useToggle from '../../hooks/useToggle';
import useFormatCurrency from '../../hooks/useFormatCurrency';
import { CountriesList } from '../../utils/countries';

import styles from './PaymentPage.module.css';

import { Currency } from '../../../__generated__/globalTypes';
import {
	CheckoutMutation,
	CheckoutMutationVariables,
	CheckoutMutation_Checkout_booking as BookingResponse,
	CheckoutMutation_Checkout_errors,
} from './__generated__/CheckoutMutation';
import CountryCodes from './CountryCodes';
import client from '../../../apollo-client';
import hotelData from '../../utils/hotelData';

export type PaymentPageProps = {};
export default function PaymentPage({}: PaymentPageProps) {
	const { t } = useTranslation('common');
	const router = useRouter();

	const [step, setStep] = useState<'payment' | '3ds'>('payment');
	const [bookingResponse, setBookingResponse] =
		useState<BookingResponse | null>(null);
	const [billingSameAsContact, toggleBillingSameAsContact] = useToggle(true);
	const [formLoading, setFormLoading] = useState(false);
	const [threeDSErrorCode, setThreeDSErrorCode] = useState(null);
	const [errors, setErrors] = useState<
		CheckoutMutation_Checkout_errors[] | null
	>([]);
	const modal = useModalState({ visible: false });
	const tAndCModal = useModalState();
	const ppModal = useModalState();
	const submitButtonRef = useRef<HTMLButtonElement | null>(null);

	const { state, completeBooking } = useGlobalState();
	const { hotelSettings, globalSettings } = useSettings();
	const hotelSlug = hotelSettings?.uri.replace(/\/$/, '') || '';

	const formatter = useFormatCurrency({
		currency: state.currentBooking?.currency || Currency.EUR,
	});

	const [threeDSInfo, setThreeDSInfo] = useState({
		screenWidth: 0,
		screenHeight: 0,
		colorDepth: 0,
		timeZoneOffset: 0,
	});

	const [clientIp, setClientIp] = useState('');
	// useEffect(() => {
	// 	// Get browser info

	// 	let ip = '';
	// 	fetch('/ip')
	// 		.then((res) => res.text())
	// 		.then((data) => {
	// 			let obj = {} as any;

	// 			data.split('\n').map((item) => {
	// 				const arr = item.split('=');

	// 				if (arr.length > 1) {
	// 					obj[arr[0]] = arr[1];
	// 				}
	// 			});

	// 			ip = obj.ip || '';
	// 			setClientIp(ip);
	// 		});
	// }, []);

	useEffect(() => {
		const date = new Date();
		setThreeDSInfo({
			screenWidth: document.body.clientWidth,
			screenHeight: document.body.clientHeight,
			colorDepth: screen.colorDepth,
			timeZoneOffset: date.getTimezoneOffset(),
		});
	}, []);

	useEffect(() => {
		if (errors && errors.length > 0) {
			window.scrollTo(0, 0);
		}
	}, [errors]);

	const handleIframeMessages = useCallback(
		(e) => {
			if (e.data.type !== '3dspayload') return;

			// 1 = success
			if (e.data.resCode > 1) {
				// error
				setThreeDSErrorCode(e.data.resCode);
				modal.setVisible(false);
				setStep('payment');
				// console.log('ERROR', e.data.resCode);
				window.scrollTo(0, 0);
			}

			if (e.data.resCode <= 1) {
				// Success!
				if (bookingResponse) completeBooking(bookingResponse);
				else console.error('No bookingResponse found');

				router.push(`${hotelSlug}/book/thank-you`);
			}
		},
		[bookingResponse, setThreeDSErrorCode, modal, setStep],
	);

	useEffect(() => {
		modal.setVisible(step === '3ds');
	}, [step]);

	// const [getCart, { loading, error, data }] = useLazyQuery(GetCartQuery);

	useEffect(() => {
		window.addEventListener('message', handleIframeMessages);

		return () => {
			window.removeEventListener('message', handleIframeMessages);
		};
	}, [bookingResponse]);

	const isDev = process.env.NODE_ENV !== 'production';

	const create3DSForm = (url, ref, fields) => {
		const form = document.createElement('form');
		form.method = 'POST';
		// form.target = '_self';
		form.target = 'ThreeDS';
		form.action = url;

		Object.keys(fields).map((key) => {
			const input = document.createElement('input');
			input.type = 'hidden';
			input.value = fields[key];
			input.name = key;
			form.appendChild(input);
		});

		document.body.appendChild(form);

		form.submit();
	};

	// setCheckout is called when form is submitted
	const [setCheckout, { loading: CheckoutLoading }] = useMutation<
		CheckoutMutation,
		CheckoutMutationVariables
	>(CheckoutMutationQuery, {
		client: client,
		onCompleted(data) {
			if (data.Checkout.success) {
				if (data.Checkout.threeDS) {
					// We need to go through 3DS. We save the updated
					// booking info so we can update globalState when
					// 3ds is complete
					setBookingResponse(data.Checkout.booking);
					// Open 3ds modal
					setStep('3ds');

					setTimeout(() => {
						create3DSForm(
							data.Checkout.threeDS?.url,
							data.Checkout.threeDS?.ref,
							JSON.parse(data.Checkout.threeDS?.request || '{}'),
						);
					}, 100);
				} else if (data.Checkout.booking) {
					completeBooking(data.Checkout.booking);
					setTimeout(
						() => router.push(`${hotelSlug}/book/thank-you`),
						50,
					);
				}
			} else {
				setErrors(data.Checkout.errors);

				data.Checkout.errors?.map((item) => {
					// @ts-ignore
					gtag('event', 'error', {
						event_category: 'payment_page_error',
						event_label: item.key,
						value: item.message,
					});
				});
			}
		},
	});

	const publishGtagSubmitEvent = () => {
		const googlePlaceId = hotelSettings?.originalId
			? hotelData[hotelSettings?.originalId]
			: undefined;

		gtag('event', 'add_payment_info', {
			currency: state.currentBooking?.currency,
			value: state.currentBooking?.totalAmount,
			affiliation: 'Website',
			coupon: state.promoCode,
			payment_type: 'Credit Card',
			items: state.currentBooking?.rooms?.map((r) => ({
				item_id: `${r.roomId}__${r.rate.rateId}`,
				item_name: r.title,
				location_id: googlePlaceId,
				adults: r.adults,
				children: r.children,
				infants: r.infants,
				price: r.rate.price,
				discount: r.rate.discount?.amount || 0,
				item_brand: hotelSettings?.title, // Hotel name
				item_category: r.rate.cancelation?.cancelBefore
					? 'Refundable'
					: 'Non-refundable', // ref/non ref
				quantity: 1,
			})),
		});
	};

	// When form has been submitted, disable submit button
	useEffect(() => {
		setFormLoading(CheckoutLoading);

		if (CheckoutLoading) {
			setThreeDSErrorCode(null);
			setErrors(null);
		}
	}, [CheckoutLoading]);

	return (
		<>
			<Grid style={{ paddingTop: 46 }}>
				{threeDSErrorCode && (
					<Grid>
						<Alert
							style={{ gridColumn: '1 / 9', marginTop: -33 }}
							variant="danger"
						>
							{t(
								threeDSErrorCode
									? `threeDsErrorCode_${threeDSErrorCode}`
									: '',
							)}
						</Alert>
					</Grid>
				)}
				{errors && errors.length > 0 && (
					<Grid>
						{errors.map((error, i) => {
							return (
								<Alert
									key={`${error.message}_${i}`}
									style={{
										gridColumn: '1 / 9',
										marginTop: -33,
									}}
								>
									{error.message}
								</Alert>
							);
						})}
					</Grid>
				)}

				<h1
					style={{
						gridColumn: '1 / -1',
						padding: '0 var(--padding-inner) 14px',
					}}
				>
					{t('paymentDetails')}
				</h1>
				<BookingAside>
					<Button
						aria-hidden
						className={styles.desktopSubmit}
						onClick={() => submitButtonRef.current?.click()}
						disabled={formLoading || step === '3ds'}
						icon={<NorthEast />}
					>
						{formLoading
							? t('paymentSubmitting')
							: state.currentBooking?.deposit
							? `${t('payDepositAmt')} ${formatter.format(
									state.currentBooking?.deposit || 0,
							  )}`
							: t('bookNow')}
					</Button>
				</BookingAside>
				<Section
					heading={
						<SectionHeading as="h2">
							{t('contactInformation')}
						</SectionHeading>
					}
					className={styles.formWrap}
				>
					<Grid
						as={Form}
						action="/api/gjafabref/bookings/payment"
						method="POST"
						onSubmit={async (e) => {
							e.preventDefault();

							const form = e.target;
							const formData = new FormData(form) as any;

							if (!formData.get('accept_disclaimer')) {
								setErrors([
									{
										__typename: 'CartError',
										key: 'disclaimer',
										message: t('disclaimerError'),
									},
								]);

								return false;
							}
							publishGtagSubmitEvent();

							var screen_width =
								window && window.screen
									? window.screen.width
									: '0';
							var screen_height =
								window && window.screen
									? window.screen.height
									: '0';
							var screen_depth =
								window && window.screen
									? window.screen.colorDepth
									: '0';
							var identity =
								window && window.navigator
									? window.navigator.userAgent
									: '';
							var language =
								window && window.navigator
									? window.navigator.language
									: '';
							var timezone = new Date().getTimezoneOffset();
							var java =
								window && window.navigator
									? navigator.javaEnabled()
									: false;

							// @ts-ignore
							grecaptcha.ready(function () {
								// @ts-ignore
								grecaptcha
									.execute(
										'6LdpR3ohAAAAANGn4HT6S6NRiqXS6HNGlzMPOK0y',
										{ action: 'submit' },
									)
									.then(function (token) {
										// Add your logic to submit to your backend server here.

										setCheckout({
											variables: {
												bookingKey:
													formData.get('bookingKey'),
												address:
													formData.get('address'),

												ccname: billingSameAsContact
													? `${formData.get(
															'fname',
													  )} ${formData.get(
															'lname',
													  )}`
													: formData.get('ccname'),
												cardnumber:
													formData.get('cardnumber'),
												ccmonth:
													formData.get('ccmonth'),
												ccyear: formData.get('ccyear'),
												acceptMarketingEmail:
													formData.get(
														'acceptMarketingEmail',
													)
														? true
														: false,
												city: formData.get('city'),
												country:
													formData.get('country'),
												cvc: formData.get('cvc'),
												email: formData.get('email'),
												fname: formData.get('fname'),
												lname: formData.get('lname'),
												phone: formData.get('phone'),
												phoneCountryCode:
													formData.get('contryCode'),
												postalCode:
													formData.get('postalCode'),
												browserScreenColourDepth:
													screen_width +
													'x' +
													screen_height +
													'x' +
													screen_depth,
												deviceTimeZone:
													String(timezone),
												deviceCapabilities:
													'javascript' +
													(java ? ',java' : ''),
												verifyToken: token,
												deviceAcceptLanguage: language,
												clientIp,
											},
										});
									});
							});
						}}
						className={styles.form}
					>
						<div className={styles.formSection}>
							<input
								name="bookingKey"
								value={state.currentBooking?.bookingKey}
								type="hidden"
							/>

							{/* {Object.keys(threeDSInfo).map((key) => {
								return (
									<input
										type="hidden"
										name={key}
										value={threeDSInfo[key]}
									/>
								);
							})} */}

							<FormInput
								className={styles.formInput}
								label={`${t('firstName')}*`}
								error={
									errors?.find((item) => item.key === 'fname')
										? t('inputRequired')
										: undefined
								}
								name="fname"
								autoComplete="given-name"
								// value="Samúel"
								required
							/>
							<FormInput
								className={styles.formInput}
								label={`${t('lastName')}*`}
								error={
									errors?.find((item) => item.key === 'lname')
										? t('inputRequired')
										: undefined
								}
								name="lname"
								// value="Smárason"
								autoComplete="family-name"
								required
							/>
							<FormInput
								className={styles.formInput}
								label={`${t('email')}*`}
								name="email"
								// value="sammi@kolofon.is"
								error={
									errors?.find((item) => item.key === 'email')
										? t('invalidEmail')
										: undefined
								}
								type="email"
								autoComplete="email"
								required
							/>
							<div
								className={`${styles.formInput} ${styles.formPhone}`}
							>
								<FormSelect
									label={`${t('countryCode')}*`}
									name="contryCode"
									autoComplete="tel-country-code"
								>
									<CountryCodes />
								</FormSelect>
								<FormInput
									label={`${t('phoneNumber')}*`}
									// value="6902931"
									error={
										errors?.find(
											(item) => item.key === 'phone',
										)
											? t('inputRequired')
											: undefined
									}
									// value={isDev ? '6902931' : undefined}
									name="phone"
									type="tel"
									autoComplete="tel-national"
									required
								/>
							</div>
							<FormInput
								className={`${styles.formInput} ${styles.formZip}`}
								label={`${t('zip')}*`}
								// value={'TE15 5ST'}
								name="postalCode"
								error={
									errors?.find(
										(item) => item.key === 'postalCode',
									)
										? t('inputRequired')
										: undefined
								}
								autoComplete="postal-code"
								required
							/>
							<FormInput
								className={`${styles.formInput} ${styles.formAddress}`}
								label={`${t('address')}*`}
								// value={'16 Test Street'}
								error={
									errors?.find(
										(item) => item.key === 'address',
									)
										? t('inputRequired')
										: undefined
								}
								name="address"
								autoComplete="street-address"
								required
							/>

							<FormInput
								className={`${styles.formInput}`}
								label={`${t('city')}*`}
								// value={'Reykjavík'}
								name="city"
								error={
									errors?.find((item) => item.key === 'city')
										? t('inputRequired')
										: undefined
								}
								autoComplete="city"
								required
							/>

							<FormSelect
								className={`${styles.formInput}`}
								name="country"
								label={`${t('country')}*`}
							>
								{CountriesList.map((country) => (
									<option value={country} key={country}>
										{country}
									</option>
								))}
							</FormSelect>
							<FormCheckbox
								className={`${styles.formInput}  ${styles.formCCName}`}
								label={t('useSameContactInfo')}
								checked={billingSameAsContact}
								onChange={toggleBillingSameAsContact}
							/>
						</div>
						<Section
							heading={
								<SectionHeading as="h3">
									{t('billingInformation')}
								</SectionHeading>
							}
							className={styles.formSection}
						>
							{!billingSameAsContact && (
								<>
									<FormInput
										className={`${styles.formInput} ${styles.formCCName}`}
										label={`${t('nameOnCard')}*`}
										// value={'Test Customer'}
										name="ccname"
										error={
											errors?.find(
												(item) => item.key === 'ccname',
											)
												? t('inputRequired')
												: undefined
										}
										autoComplete="cc-name"
										required
									/>
									<div />
									<FormInput
										className={`${styles.formInput} ${styles.formZip}`}
										label={`${t('zip')}*`}
										// value={'TE15 5ST'}
										name="postalCode"
										error={
											errors?.find(
												(item) =>
													item.key === 'postalCode',
											)
												? t('inputRequired')
												: undefined
										}
										autoComplete="postal-code"
										required
									/>
									<FormInput
										className={`${styles.formInput} ${styles.formAddress}`}
										label={`${t('address')}*`}
										// value={'16 Test Street'}
										name="address"
										error={
											errors?.find(
												(item) =>
													item.key === 'address',
											)
												? t('inputRequired')
												: undefined
										}
										autoComplete="street-address"
										required
									/>

									<FormInput
										className={`${styles.formInput}`}
										label={`${t('city')}*`}
										// value={'Reykjavík'}
										name="city"
										error={
											errors?.find(
												(item) => item.key === 'city',
											)
												? t('inputRequired')
												: undefined
										}
										autoComplete="city"
										required
									/>

									<FormSelect
										className={`${styles.formInput}`}
										name="country"
										label={`${t('country')}*`}
									>
										{CountriesList.map((country) => (
											<option
												value={country}
												key={country}
											>
												{country}
											</option>
										))}
									</FormSelect>
								</>
							)}

							<FormInput
								className={styles.formInput}
								label={`${t('cardNumber')}*`}
								// value={'4012001037141112'}
								name="cardnumber"
								error={
									errors?.find(
										(item) => item.key === 'cardnumber',
									)
										? t('invalidCardNumber')
										: undefined
								}
								minLength={15}
								maxLength={16}
								autoComplete="cc-number"
								required
							/>
							<FormSelect
								className={`${styles.formInput} ${styles.formCCMonth}`}
								label={`${t('month')}*`}
								// value={'12'}
								name="ccmonth"
								error={
									errors?.find(
										(item) => item.key === 'ccmonth',
									)
										? t('inputRequired')
										: undefined
								}
								autoComplete="cc-exp-month"
								required
							>
								<option>01</option>
								<option>02</option>
								<option>03</option>
								<option>04</option>
								<option>05</option>
								<option>06</option>
								<option>07</option>
								<option>08</option>
								<option>09</option>
								<option>10</option>
								<option>11</option>
								<option>12</option>
							</FormSelect>

							<FormSelect
								className={`${styles.formInput} ${styles.formCCYear}`}
								label={`${t('year')}*`}
								name="ccyear"
								list="ccyears-list"
								autoComplete="cc-exp-year"
								required
								error={
									errors?.find(
										(item) => item.key === 'ccyear',
									)
										? t('inputRequired')
										: undefined
								}
							>
								{eachYearOfInterval({
									start: new Date(),
									end: addYears(new Date(), 6),
								}).map((date) => (
									<option
										value={date.getFullYear()}
										key={date.getFullYear()}
									>
										{date.getFullYear()}
									</option>
								))}
							</FormSelect>

							<FormInput
								className={`${styles.formInput} ${styles.formCCCVC}`}
								label={`${t('cvc')}*`}
								// value={'083'}
								error={
									errors?.find((item) => item.key === 'cvc')
										? t('inputRequired')
										: undefined
								}
								name="cvc"
								autoComplete="cc-csc"
								minLength={3}
								maxLength={4}
								required
							/>
							<div style={{ gridColumn: '1 / -1' }}>
								<FormCheckbox
									name="accept_disclaimer"
									error={
										errors?.find(
											(item) => item.key === 'disclaimer',
										)
											? t('disclaimerError')
											: undefined
									}
									label={
										<>
											{t('accept')}
											<ModalDisclosure
												{...tAndCModal}
												className={styles.modalButton}
											>
												{t('terms')}
											</ModalDisclosure>{' '}
											{t('and')}
											<ModalDisclosure
												{...ppModal}
												className={styles.modalButton}
											>
												{t('privacyPolicy')}
											</ModalDisclosure>
										</>
									}
									// checked={true}
								/>
							</div>

							<div
								style={{
									gridColumn: '1 / -1',
								}}
							>
								<FormCheckbox
									name="acceptMarketingEmail"
									label={t('marketingEmailSignup')}
									error={
										errors?.find(
											(item) => item.key === 'ccyear',
										)
											? t('inputRequired')
											: undefined
									}
								/>
							</div>

							<p
								className={styles.recaptcha_footnote}
								style={{
									gridColumn: '1 / -1',
								}}
								dangerouslySetInnerHTML={{
									__html: `
								This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
							`,
								}}
							></p>
							<Modal
								control={tAndCModal}
								className={styles.disclaimer}
								wrapClassName={styles.modalWrap}
							>
								<h2>{t('terms')}</h2>

								{state.currentBooking?.rooms?.map((item, i) => {
									return (
										<Fragment key={`${item.title}_${i}`}>
											<h3>
												{item.title} —{' '}
												{item.rate.description}
											</h3>

											<div
												className={
													styles.disclaimerText
												}
												dangerouslySetInnerHTML={{
													__html:
														item.rate.policies
															.rate || '',
												}}
											/>

											<h4>{t('termsCancelation')}</h4>
											<div
												className={
													styles.disclaimerText
												}
												dangerouslySetInnerHTML={{
													__html: item.rate.policies
														.cancelation,
												}}
											/>
											<h4>{t('termsPayment')}</h4>
											<div
												className={
													styles.disclaimerText
												}
												dangerouslySetInnerHTML={{
													__html: item.rate.policies
														.payment,
												}}
											/>
										</Fragment>
									);
								})}
								<Button
									className={styles.modalCloseButton}
									onClick={tAndCModal.hide}
								>
									{t('close')}
								</Button>
							</Modal>
							<Modal
								control={ppModal}
								className={styles.modalContent}
								wrapClassName={styles.modalWrap}
							>
								<div
									dangerouslySetInnerHTML={{
										__html:
											globalSettings?.privacyPolicy || '',
									}}
								/>
								<Button
									className={styles.modalCloseButton}
									onClick={ppModal.hide}
								>
									{t('close')}
								</Button>
							</Modal>

							<Button
								className={styles.mobileSubmit}
								type="submit"
								disabled={formLoading || step === '3ds'}
								icon={<NorthEast />}
								ref={submitButtonRef}
							>
								{formLoading
									? t('paymentSubmitting')
									: state.currentBooking?.deposit
									? `${t('payDepositAmt')} ${formatter.format(
											state.currentBooking?.deposit || 0,
									  )}`
									: t('bookNow')}
							</Button>
						</Section>
					</Grid>
				</Section>
			</Grid>
			<Modal control={modal} hideOnEsc={false} hideOnClickOutside={false}>
				<div>
					<iframe
						className={styles.threeDSCheck}
						name="ThreeDS"
						id="ThreeDS"
						width="400px"
						height="465px"
					></iframe>
				</div>
			</Modal>
		</>
	);
}

export const CartFragment = gql`
	fragment CartFragment on Cart {
		bookingKey
		rateIds
		totalAmount
		deposit
		currency
		checkIn
		checkOut
		rooms {
			roomId
			title
			adults
			children
			infants
			rate {
				rateId
				description
				price
				currency
				discount {
					amount
					description
				}
				cancelation {
					policy
					cancelBefore
				}
				policies {
					rate
					payment
					cancelation
				}
			}
		}
		addons {
			title
		}
	}
`;

const GetCartQuery = gql`
	query GetCart($key: String!) {
		Cart(key: $key) {
			...CartFragment
		}
	}
	${CartFragment}
`;

const CheckoutMutationQuery = gql`
	mutation CheckoutMutation(
		$bookingKey: String!
		$ccmonth: String!
		$ccyear: String!
		$ccname: String!
		$cvc: String!
		$cardnumber: String!
		$email: String!
		$address: String!
		$city: String!
		$country: String!
		$postalCode: String!
		$fname: String!
		$lname: String!
		$phone: String!
		$phoneCountryCode: String!
		$acceptMarketingEmail: Boolean!
		$browserScreenColourDepth: String!
		$deviceCapabilities: String!
		$deviceTimeZone: String!
		$deviceAcceptLanguage: String!
		$clientIp: String
		$verifyToken: String
	) {
		Checkout(
			bookingKey: $bookingKey
			ccmonth: $ccmonth
			ccyear: $ccyear
			ccname: $ccname
			cvc: $cvc
			cardnumber: $cardnumber
			email: $email
			address: $address
			city: $city
			country: $country
			postalCode: $postalCode
			fname: $fname
			lname: $lname
			phone: $phone
			phoneCountryCode: $phoneCountryCode
			acceptMarketingEmail: $acceptMarketingEmail
			browserScreenColourDepth: $browserScreenColourDepth
			deviceCapabilities: $deviceCapabilities
			deviceTimeZone: $deviceTimeZone
			deviceAcceptLanguage: $deviceAcceptLanguage
			clientIp: $clientIp
			verifyToken: $verifyToken
		) {
			success
			errors {
				key
				message
			}
			threeDS {
				request
				url
				ref
			}
			booking {
				bookingKey
			}
		}
	}
`;
