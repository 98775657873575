import { createContext, ReactNode, useContext } from 'react';
import 'focus-visible';
import Head from 'next/head';
import { DefaultSeo } from 'next-seo';

import { SkipNavLink, SkipNavContent } from '@reach/skip-nav';

import { TopBar } from '../TopBar/TopBar';
import AppFooter from '../AppFooter/AppFooter';
import { StateContext, useCreateGlobalState } from '../../hooks/useGlobalState';

import styles from './AppLayout.module.css';

import useBodyClassName from '../../hooks/useBodyClassName';
import getHotelClassName from '../../utils/getHotelClassName';

import {
	GetGlobalData,
	GetGlobalData_globalSettings,
	GetGlobalData_hotelSettings_PageHotel,
} from '../../hooks/__generated__/GetGlobalData';
import { PageProps } from '../../templates/Page/Page';
import { TranslationFragment } from '../../queries/__generated__/TranslationFragment';
import CookieBanner from '../CookieBanner/CookieBanner';
import Script from 'next/script';

export const SettingsContext = createContext<{
	hotelSettings?: GetGlobalData_hotelSettings_PageHotel;
	globalSettings?: GetGlobalData_globalSettings;
	translations?: (TranslationFragment | null)[];
}>({});

export const useSettings = () => {
	return useContext(SettingsContext);
};

// Vercel

type LayoutProps = {
	children: ReactNode;
} & Partial<PageProps> &
	GetGlobalData;

export default function AppLayout({
	children,
	slug = '/',
	page,
	hotelSettings,
	globalSettings,
	locale = 'en',
}: LayoutProps) {
	const globalState = useCreateGlobalState();

	const translations = page?.translations
		? page.translations
		: hotelSettings?.__typename === 'PageHotel' &&
		  hotelSettings.translations
		? hotelSettings.translations
		: undefined;

	const settings = {
		hotelSettings:
			hotelSettings?.__typename === 'PageHotel'
				? hotelSettings
				: undefined,
		globalSettings: globalSettings,
		translations,
	};

	const hotelClassName = getHotelClassName(
		settings.hotelSettings?.originalId,
	);

	useBodyClassName(hotelClassName);

	return (
		<StateContext.Provider value={globalState}>
			<Head>
				<link
					rel="icon"
					href="/favicon/kea-icon.svg"
					type="image/svg+xml"
				/>
				<link rel="icon" href="/favicon.ico" sizes="any" />
				<link
					rel="apple-touch-icon"
					href="/favicon/apple-touch-icon.png"
				/>
				<link rel="manifest" href="/manifest.webmanifest" />
				<link
					rel="preload"
					href="https://api.tiles.mapbox.com/mapbox-gl-js/v2.6.1/mapbox-gl.css"
					as="style"
					onLoad={function onLoad() {
						// @ts-ignore
						this.onload = null;
						// @ts-ignore
						this.rel = 'stylesheet';
					}}
				/>
				<link rel="preconnect" href="https://api.keahotels.is" />
				<link
					rel="preconnect"
					href="https://www.google-analytics.com"
				/>
				<link
					rel="preconnect"
					href="https://www.googletagmanager.com"
				/>
				<link rel="preconnect" href="https://api.triptease.io" />
				<meta
					name="google-site-verification"
					content="tE7AESh14LNCoKpXxiZslvNKCkuf7WoLd_jIc5Xy1AU"
				/>
			</Head>
			<Script src="https://www.google.com/recaptcha/api.js?render=6LdpR3ohAAAAANGn4HT6S6NRiqXS6HNGlzMPOK0y"></Script>

			<DefaultSeo
				title={`${page?.seo?.title || page?.title || 'Keahotels'}`}
				description={page?.seo?.description || undefined}
				openGraph={{
					type: 'website',
					locale,
					title: `${page?.seo?.title || page?.title}`,
					site_name: 'Keahotels',
					url: 'https://keahotels.is' + slug,
					images: page?.seo?.openGraphImage
						? [
								{
									url: page.seo.openGraphImage,
									width: 1200,
									height: 630,
								},
						  ]
						: undefined,
				}}
			/>
			<div className={styles.layout}>
				<CookieBanner />

				<SkipNavLink contentId="main-content">
					Skip to main content
				</SkipNavLink>

				<SettingsContext.Provider value={settings}>
					<TopBar />

					{/* <Breadcrumbs items={breadcrumbs} current={currentPage} /> */}

					<SkipNavContent
						as="main"
						id="main-content"
						className={styles.main}
					>
						{children}
					</SkipNavContent>

					<AppFooter />
				</SettingsContext.Provider>
			</div>
		</StateContext.Provider>
	);
}
