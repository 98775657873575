import { ApolloClient, InMemoryCache } from '@apollo/client';

const defaultOptions = {
	watchQuery: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'ignore',
	},
	query: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'all',
	},
};

const cache = new InMemoryCache();

const client = new ApolloClient({
	uri: process.env.NEXT_PUBLIC_GRAPHQL,
	cache,
	defaultOptions,
});

export default client;
