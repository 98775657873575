import { VisuallyHidden } from 'reakit/VisuallyHidden';
import c from 'classnames';

import { Alert, Grid } from '@keahotels/components';

import BookingSearch from '../AppDatePicker/AppDatePicker';
import MainMenu from '../MainMenu/MainMenu';
import HotelLogo from '../HotelLogo/HotelLogo';
import Link from '../Link/Link';

import { useSettings } from '../AppLayout/AppLayout';

import { ReactComponent as Bedroom } from '@keahotels/assets/icons/Bedroom.svg';

import styles from './HotelBookingToolbar.module.css';
import { GetGlobalData_hotelSettings_PageHotel_menu } from '../../hooks/__generated__/GetGlobalData';

export type HotelBookingToolbarProps = {
	hideMenu?: boolean;
	hideBookingSearch?: boolean;
	menu?: GetGlobalData_hotelSettings_PageHotel_menu | null | undefined;
};

export default function HotelBookingToolbar({
	hideMenu,
	hideBookingSearch,
	menu,
}: HotelBookingToolbarProps) {
	const { hotelSettings } = useSettings();
	const alert = false;
	// In the beta phase we had a beta notice alert hard-coded
	// in. We should ideally hook this up to Wordpress
	// const alert = t(
	// 	hotelSettings?.originalId === 5437
	// 		? 'betaBannerWithoutOriginal'
	// 		: 'betaBanner',
	// );

	return (
		<Grid className={styles.wrap}>
			{alert && (
				<Alert className={styles.alert} icon={<Bedroom />}>
					<div dangerouslySetInnerHTML={{ __html: alert }} />
				</Alert>
			)}
			{hotelSettings ? (
				<Link className={styles.logo} href={hotelSettings?.uri || '/'}>
					<VisuallyHidden as="h1">
						{hotelSettings?.title || 'Keahotels'}
					</VisuallyHidden>
					<HotelLogo />
				</Link>
			) : null}

			{!hideMenu && <MainMenu menu={menu} />}

			<div
				className={c({
					[styles.bookingToolbar]: 1,
					[styles.fullWidth]: !hotelSettings,
				})}
			>
				{!hideBookingSearch && <BookingSearch />}
			</div>
		</Grid>
	);
}
