import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { createContext, ReactNode, useCallback, useContext } from 'react';

import c from 'classnames';

import client from '../../../apollo-client';
import { Currency } from '../../../__generated__/globalTypes';

import styles from './PriceProvider.module.css';

import {
	GetRatePrices,
	GetRatePricesVariables,
	GetRatePrices_AvailabilityCalendar_dates,
} from './__generated__/GetRatePrices';
import useFormatCurrency from '../../hooks/useFormatCurrency';
import dateToString from '../../utils/dateToString';
import { isNull } from 'lodash';

const GetRatePricesQuery = gql`
	query GetRatePrices(
		$hotelId: Int!
		$from: String!
		$to: String!
		$currency: Currency!
		$adults: Int!
		$kids: Int
		$rateId: Int
		$roomId: Int
		$promoCode: String
	) {
		AvailabilityCalendar(
			hotelId: $hotelId
			dateFrom: $from
			dateTo: $to
			currency: $currency
			adults: $adults
			children: $kids
			rateId: $rateId
			roomId: $roomId
			promoCode: $promoCode
		) {
			dates {
				date
				price
				priceWithDiscount
				availability
			}
		}
	}
`;

export function useGetPrices(
	options?: QueryHookOptions<GetRatePrices, GetRatePricesVariables>,
) {
	return useQuery<GetRatePrices, GetRatePricesVariables>(GetRatePricesQuery, {
		client,
		fetchPolicy: 'cache-first',
		...options,
	});
}

const PriceContext = createContext<
	(date: Date) => {
		loading: boolean;
		data: GetRatePrices_AvailabilityCalendar_dates | null;
		currency: Currency;
		isAvailable: boolean;
		isHotelSelected: boolean;
	}
>((date) => ({
	loading: true,
	data: null,
	currency: Currency.EUR,
	isAvailable: false,
	isHotelSelected: false,
}));

export type PriceProviderProps = {
	from: Date;
	to: Date;
	rateId?: number;
	roomId?: number;
	hotelId: number | null;
	adults: number;
	kids: number;
	currency: Currency;
	children: ReactNode;
	minimumAvailability: number;
	promoCode?: string;
};

export const useGetPrice = (date: Date) => {
	const getPrice = useContext(PriceContext);
	return getPrice(date);
};

export default function PriceProviderWithQuery({
	from,
	to,
	rateId,
	roomId,
	hotelId,
	adults,
	currency,
	kids,
	children,
	minimumAvailability,
	promoCode,
}: PriceProviderProps) {
	const { data, loading } = useGetPrices({
		variables: {
			from: dateToString(from),
			to: dateToString(to),
			hotelId: hotelId || 0,
			roomId,
			adults,
			kids,
			currency,
			rateId,
			promoCode,
		},
		skip: isNull(hotelId),
	});

	const getPrice = useCallback(
		(date: Date) => {
			if (loading || !data) {
				return {
					loading,
					data: null,
					currency: Currency.EUR,
					isAvailable: false,
					isHotelSelected: !!hotelId,
				};
			}
			const dateString = dateToString(date);

			const datePrice =
				data?.AvailabilityCalendar?.dates?.find(
					(current) => current?.date === dateString,
				) || null;
			const isAvailable =
				(datePrice?.availability || 0) >= minimumAvailability;

			return {
				loading,
				data: datePrice,
				currency,
				isAvailable,
				isHotelSelected: !!hotelId,
			};
		},
		[data, hotelId, loading, currency, minimumAvailability],
	);

	return (
		<PriceContext.Provider value={getPrice}>
			{children}
		</PriceContext.Provider>
	);
}

type PriceProps = { date: Date };

export const Price = ({ date }: PriceProps) => {
	const { loading, data, currency, isAvailable, isHotelSelected } =
		useGetPrice(date);

	const formatter = useFormatCurrency({ currency, maximumFractionDigits: 0 });
	return (
		<span
			className={c({
				[styles.price]: data?.availability,
				[styles.priceLoading]: loading,
			})}
		>
			{loading && !data ? (
				'€000'
			) : data && isAvailable ? (
				formatter.format(data.priceWithDiscount)
			) : date < new Date() || !isHotelSelected ? (
				<>&nbsp;</>
			) : (
				'-'
			)}
		</span>
	);
};
