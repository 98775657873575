import { gql } from '@apollo/client';

import { Button, Card, Grid } from '@keahotels/components';
import { ReactComponent as NorthEast } from '@keahotels/assets/icons/NorthEast.svg';

import styles from './BlockContentCards.module.css';
import {
	BlockContentCardsFragment_BlockContent_cards,
	BlockContentCardsFragment_BlockContent_cards_cards as CardType,
} from './__generated__/BlockContentCardsFragment';
import isNotNull from '../../utils/isNotNull';
import Link from '../Link/Link';
import Image from '../Image/Image';
import { useGetCurrency } from '../../hooks/useGlobalState';
import useFormatCurrency from '../../hooks/useFormatCurrency';
import useTranslation from 'next-translate/useTranslation';
const BlockContentCard = ({ title, intro, image, price, uri }: CardType) => {
	const currency = useGetCurrency();
	const { t } = useTranslation('common');
	const { format } = useFormatCurrency({
		currency,
		maximumFractionDigits: 0,
	});
	const priceInCurrency =
		price && price[currency.toString()]
			? format(price[currency.toString()])
			: false;

	return (
		<Card
			as={Link}
			href={uri}
			image={
				image?.url ? <Image {...image} aspectRatio="landscape" /> : null
			}
			footer={
				<Button variant="clear" icon={<NorthEast />}>
					{priceInCurrency
						? t('bookFromAmount', {
								amount: priceInCurrency,
						  })
						: t('readMore')}
				</Button>
			}
		>
			<h3>{title}</h3>
			{intro && <div dangerouslySetInnerHTML={{ __html: intro }} />}
		</Card>
	);
};

export type BlockContentCardsProps =
	BlockContentCardsFragment_BlockContent_cards;

export default function BlockContentCards({ cards }: BlockContentCardsProps) {
	if (!cards) return null;
	return (
		<Grid>
			<div className={styles.content}>
				{cards.filter(isNotNull).map((c) => (
					<BlockContentCard key={c.id} {...c} />
				))}
			</div>
		</Grid>
	);
}

export const BlockContentCardsFragment = gql`
	fragment BlockContentCardsFragment on BlockItem {
		... on BlockContent_cards {
			cards {
				id
				image {
					...ImageFragment
				}
				price {
					EUR: eur
					ISK: isk
				}
				uri
				title
				intro
			}
		}
	}
`;
