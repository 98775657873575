export default function isNotNull<T>(arg: T): arg is Exclude<T, null> {
	return arg !== null;
}

export function isNotUndefined<T>(arg: T): arg is Exclude<T, undefined> {
	return arg !== null;
}

export function isNotNullOrUndefined<T>(
	arg: T,
): arg is Exclude<Exclude<T, undefined>, null> {
	return arg !== null;
}
