import styles from './MainHeader.module.css';

import {
	Slider,
	SliderImage,
	Slide,
	useSliderState,
} from '@keahotels/components';
import Image, { ImageFragment } from '../Image/Image';
import isNotNull from '../../utils/isNotNull';
import { imageSizes } from './MainHeader';
import { MainHeaderFragment_HeroHeader_slider_MainHeaderImageSlider } from './__generated__/MainHeaderFragment';
import { useEffect } from 'react';
import useInterval from '../../hooks/useInterval';

type Props = {
	headerType: keyof typeof imageSizes;
} & MainHeaderFragment_HeroHeader_slider_MainHeaderImageSlider;

export default function MainHeaderImageSlider({ images, headerType }: Props) {
	const sliderControl = useSliderState({});
	const { reset } = useInterval(() => sliderControl.next(), 5000);
	useEffect(() => {
		reset();
	}, [sliderControl.currentId, reset]);

	return (
		<Slider
			aria-label={''}
			slides={(images || [])
				.filter(isNotNull)
				.map(({ title, image }, i) => {
					if (!image) return null;
					return {
						id: image.url,
						background: (
							<SliderImage
								as={Image}
								{...image}
								priority={i === 0}
								{...imageSizes[headerType]}
							/>
						),
						title: title || image.caption,
					} as Slide;
				})
				.filter(isNotNull)}
			control={sliderControl}
			className={styles.slider}
			overlay="none"
			isMainHeader
		/>
	);
}
