import { useCallback, useEffect, useRef } from 'react';

type Callback = () => void;

export default function useInterval(callback: Callback, delay: number | null) {
	const savedCallback = useRef<Callback>();
	const intervalId = useRef<NodeJS.Timer>();

	const reset = useCallback(() => {
		function tick() {
			savedCallback.current?.();
		}
		if (intervalId.current) {
			clearInterval(intervalId.current);
		}
		if (delay !== null) {
			intervalId.current = setInterval(tick, delay);
		}
	}, [delay]);

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		function tick() {
			savedCallback.current?.();
		}
		if (delay !== null) {
			intervalId.current = setInterval(tick, delay);
			return () => {
				if (intervalId.current) {
					clearInterval(intervalId.current);
					intervalId.current = undefined;
				}
			};
		}
		return undefined;
	}, [delay]);

	return {
		reset,
	};
}
