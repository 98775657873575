import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { format, isSameDay, addMonths, addDays, startOfMonth } from 'date-fns';
import { is, enUS } from 'date-fns/locale';

import {
	BookingSelect,
	BookingSelectInput,
	Button,
	DatePicker,
	useBookingSelectState,
	FormInput,
} from '@keahotels/components';

import { ReactComponent as East } from '@keahotels/assets/icons/East.svg';
import { ReactComponent as Search } from '@keahotels/assets/icons/Search.svg';

import useGlobalState, {
	DateRange,
	useGetCurrency,
} from '../../hooks/useGlobalState';

import styles from './AppDatePicker.module.css';
import Link from '../Link/Link';
import GuestsPicker from './GuestsPicker';
import { useSettings } from '../AppLayout/AppLayout';
import dateToString from '../../utils/dateToString';
import PriceProvider, { Price } from '../PriceProvider/PriceProvider';
import hotelData from '../../utils/hotelData';
import { HotelLocationsList } from '../LocationMenu/LocationMenu';
import { useRouter } from 'next/router';
import useWindowSize from '../../hooks/useWindowSize';

export const selectHotelHashLink = '#select-hotel';
export const selectDateHashLink = '#select-date';

const useCalendarMonth = (from: Date | null, isPickerOpen: boolean) => {
	const [storedMonth, setStoredMonth] = useState(
		startOfMonth(from || new Date()),
	);
	const setBeginningOfMonth = (d: Date) => setStoredMonth(startOfMonth(d));
	useEffect(() => {
		if (
			!isPickerOpen &&
			from &&
			startOfMonth(from).valueOf() !== storedMonth?.valueOf()
		) {
			setBeginningOfMonth(from);
		}
	}, [from, isPickerOpen, storedMonth]);
	return [storedMonth, setBeginningOfMonth] as [
		Date,
		typeof setBeginningOfMonth,
	];
};

export const BookingSelectedDates = ({
	from,
	to,
	underline = true,
	className = '',
}: DateRange & { underline?: boolean; className?: string }) => {
	const { t, lang } = useTranslation('common');

	return (
		<div className={`${styles.bookingSelectValue} ${className}`}>
			<BookingSelectInput
				label={t('check-in')}
				underline={underline}
				value={
					from ? (
						<>
							{format(
								from,
								lang === 'is' ? 'E d. MMM' : 'E. MMM d',
								{
									locale: lang === 'is' ? is : undefined,
								},
							)}
							<span className={styles.notMobile}>
								{lang === 'is' ? ' ' : ', '}
								{format(from, 'YYY', {
									locale: lang === 'is' ? is : undefined,
								})}
							</span>
						</>
					) : (
						<span className={styles.fallback}>
							{t('selectDate')}
						</span>
					)
				}
			/>
			<East className={styles.arrow} />
			<BookingSelectInput
				label={t('check-out')}
				underline={underline}
				value={
					to ? (
						<>
							{format(
								to,
								lang === 'is' ? 'E d. MMM' : 'E. MMM d',
								{
									locale: lang === 'is' ? is : undefined,
								},
							)}
							<span className={styles.notMobile}>
								{lang === 'is' ? ' ' : ', '}
								{format(to, 'YYY', {
									locale: lang === 'is' ? is : undefined,
								})}
							</span>
						</>
					) : (
						<span className={styles.fallback}>
							{t('selectDate')}
						</span>
					)
				}
			/>
		</div>
	);
};

export type AppDatePickerProps = {
	small?: boolean;
};

export default function AppDatePicker({ small }: AppDatePickerProps) {
	const router = useRouter();
	const { t, lang } = useTranslation('common');
	const { state, updateDates, updatePromoCode, toggleSelectedHotel } =
		useGlobalState();
	const currency = useGetCurrency();
	const { hotelSettings } = useSettings();
	const hotelPickerController = useBookingSelectState({ modal: false });
	const datePickerController = useBookingSelectState({ modal: false });
	const guestsController = useBookingSelectState({
		placement: 'bottom-end',
		modal: false,
	});

	const { dates, selectedHotels, rooms } = state;
	const { from, to } = dates;
	const [selectedMonth, setSelectedMonth] = useCalendarMonth(
		from,
		datePickerController.visible,
	);

	const totalGuests = rooms.reduce((total, { adults, children, infants }) => {
		return total + adults + children + infants;
	}, 0);

	const hotelSlug =
		hotelSettings?.uri.replace(/\/$/, '') ||
		(selectedHotels.length && hotelData[selectedHotels[0]].slug) ||
		'';

	const hasChildren = rooms.some((r) => !!(r.children + r.infants));

	let selectedHotelBonId: number | null = null;
	if (hotelSettings?.bonId) {
		selectedHotelBonId = hotelSettings?.bonId;
	} else if (
		state.selectedHotels.length &&
		hotelData[state.selectedHotels[0]]
	) {
		selectedHotelBonId = hotelData[state.selectedHotels[0]].bonId;
	}

	const windowSize = useWindowSize();
	const isMobile = (windowSize.width || 0) < 1100;

	const numberOfMonths = isMobile ? 14 : 2;

	// Make sure that there's to date when datePicker is closed
	useEffect(() => {
		if (!datePickerController.visible && from && !to) {
			updateDates({ from: from, to: addDays(from, 1) });
		}
	}, [datePickerController.visible, from, to, updateDates]);

	// Enable opening hotel picker and date picker with a hash
	// in route (e.g. /sand-hotel/#select-hotel)
	useEffect(() => {
		if (window.location.hash === selectHotelHashLink) {
			hotelPickerController.show();
			router.replace(router.pathname);
		}
		if (window.location.hash === selectDateHashLink) {
			datePickerController.show();
			router.replace(router.pathname);
		}
	}, [router, hotelPickerController, datePickerController]);

	return (
		<>
			{!hotelSettings && (
				<BookingSelect
					buttonLabel={t('SelectHotel')}
					controller={hotelPickerController}
					value={
						<div className={styles.bookingSelectValue}>
							<BookingSelectInput
								label="Hotel"
								value={
									state.selectedHotels.length === 0 ? (
										<span
											style={{
												color: 'var(--color-text-secondary)',
											}}
										>
											{t('SelectHotel')}
										</span>
									) : state.selectedHotels.length === 1 ? (
										hotelData[state.selectedHotels[0]].name
									) : (
										`${state.selectedHotels.length} hotels`
									)
								}
							/>
						</div>
					}
				>
					<div
						style={{
							width: 243,
						}}
					>
						<HotelLocationsList
							isVisible={hotelPickerController.visible}
							onClickItem={(id) => {
								toggleSelectedHotel(id);
								hotelPickerController.hide();
							}}
							asButton
						/>
					</div>
				</BookingSelect>
			)}
			<BookingSelect
				buttonLabel={t('selectDatesButtonLabel')}
				controller={datePickerController}
				value={<BookingSelectedDates from={from} to={to} />}
			>
				<PriceProvider
					from={selectedMonth}
					to={addMonths(selectedMonth, numberOfMonths)}
					hotelId={selectedHotelBonId}
					adults={totalGuests || 2}
					currency={currency}
					kids={0}
					minimumAvailability={1}
					promoCode={state.promoCode}
				>
					<DatePicker
						Price={Price}
						min={1}
						locale={lang === 'is' ? is : enUS}
						numberOfMonths={numberOfMonths}
						onSelect={(dates) => {
							const newDates = {
								from: dates?.from || null,
								to:
									dates?.to &&
									!isSameDay(
										dates?.from || new Date(),
										dates?.to || new Date(),
									)
										? dates?.to
										: null,
							};
							updateDates(newDates);
						}}
						selected={{
							from: from || undefined,
							to: to || undefined,
						}}
						onMonthChange={(date) => setSelectedMonth(date)}
						month={selectedMonth}
					/>
				</PriceProvider>
			</BookingSelect>

			<BookingSelect
				buttonLabel={t('select-guests')}
				controller={guestsController}
				value={
					<div className={styles.bookingSelectValue}>
						<BookingSelectInput
							label={
								<>
									<span className={styles.notMobile}>
										{t('GuestsAndOptions')}
									</span>
									<span
										className={styles.mobileOnly}
										aria-hidden
									>
										{t('options')}
									</span>
								</>
							}
							value={
								hasChildren
									? `${totalGuests} ${t('guestsValuePlural')}`
									: `${totalGuests} ${t(
											totalGuests === 1
												? 'adultsValueSingular'
												: 'adultsValuePlural',
									  )}${
											state.rooms.length > 1
												? `, ${t('roomsCount', {
														count: state.rooms
															.length,
												  })}`
												: ''
									  }`
							}
						/>
					</div>
				}
			>
				<GuestsPicker />

				<div className={styles.promo}>
					<FormInput
						className={styles.formInput}
						label={t('promoCode')}
						name="promocode"
						onBlur={(e) => updatePromoCode(e.currentTarget.value)}
						defaultValue={state.promoCode || ''}
					/>
				</div>
			</BookingSelect>
			<Button
				as={Link}
				icon={<Search />}
				className={styles.searchButton}
				classNames={{ text: styles.searchText }}
				onClick={(e: any) => {
					if (!(to && from)) {
						e.preventDefault();
						datePickerController.show();
						return;
					}
					if (!hotelSlug) {
						e.preventDefault();
						hotelPickerController.show();
						return;
					}
				}}
				// disabled={!!dates.from && !!dates.to}
				href={
					!from
						? selectDateHashLink
						: !hotelSlug
						? selectHotelHashLink
						: `${hotelSlug}/book?from=${dateToString(
								from,
						  )}&to=${dateToString(
								to || addDays(from, 1),
						  )}${state.rooms.map(
								(r) =>
									`&rooms=${r.adults},${r.children},${r.infants}`,
						  )}&currency=${currency}${
								state.promoCode
									? `&promo=${state.promoCode}`
									: ''
						  }
							`
				}
				aria-label={t('search')}
			>
				{t('search')}
			</Button>
		</>
	);
}
