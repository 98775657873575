import { CSSProperties, MutableRefObject, useRef, useState } from 'react';
import Video from '../Video/Video';

import { ReactComponent as Play } from '@keahotels/assets/icons/Play.svg';
import { ReactComponent as Pause } from '@keahotels/assets/icons/Pause.svg';

import styles from './MainHeader.module.css';
import { MainHeaderFragment_HeroHeader_slider_MainHeaderVideo } from './__generated__/MainHeaderFragment';
import Image from '../Image/Image';
import { imageSizes } from './MainHeader';

const useVideoControls = (
	videoRef: MutableRefObject<HTMLVideoElement | undefined>,
) => {
	const [isPlaying, setIsPlaying] = useState(true);
	const [currentTime, setCurrentTime] = useState(0);

	setInterval(() => {
		if (isPlaying && videoRef.current) {
			setCurrentTime(videoRef.current.currentTime);
		}
	}, 1000);

	function togglePlay() {
		setIsPlaying(!isPlaying);
		if (videoRef.current) {
			setCurrentTime(videoRef.current.currentTime);
			if (isPlaying) videoRef.current.pause();
			else videoRef.current.play();
		}
	}

	return {
		togglePlay,
		isPlaying,
		currentTime,
		setCurrentTime,
	};
};

export default function MainHeaderVideoSlider({
	video,
	keyframes,
	image,
	headerType,
}: MainHeaderFragment_HeroHeader_slider_MainHeaderVideo & {
	headerType: keyof typeof imageSizes;
}) {
	const videoRef = useRef<HTMLVideoElement>();
	const { isPlaying, currentTime, setCurrentTime, togglePlay } =
		useVideoControls(videoRef);

	const poster = image
		? process.env.NEXT_PUBLIC_IMAGE_URL + image.url
		: undefined;
	const duration = videoRef.current?.duration || 1000000;

	return (
		<>
			{image?.url && (
				<Image
					{...image}
					{...imageSizes[headerType]}
					className={styles.image}
				/>
			)}
			<Video
				{...imageSizes[headerType]}
				src={`${process.env.NEXT_PUBLIC_IMAGE_URL}/wp-content/uploads/${video?.uri}`}
				autoPlay
				loop
				muted
				playsInline
				captionPosition="inside"
				// @ts-ignore
				ref={videoRef}
				poster={poster}
				caption={
					<>
						<button
							onClick={togglePlay}
							className={styles.playPauseButton}
						>
							{isPlaying ? <Pause /> : <Play />}
						</button>
						{keyframes.map(({ title, time }, i) => {
							const isCurrentFrom = i === 0 ? 0 : time + 1;
							const isCurrentTo =
								i === keyframes.length - 1
									? duration
									: keyframes[i + 1].time + 1;
							const isCurrent =
								currentTime >= isCurrentFrom &&
								currentTime < isCurrentTo;
							const timelineWidth = isCurrent
								? (currentTime - isCurrentFrom) /
								  (isCurrentTo - isCurrentFrom)
								: undefined;
							return (
								<button
									key={time}
									className={
										isCurrent
											? styles.currentVideoKeyframe
											: undefined
									}
									style={
										{
											'--timeline-width': timelineWidth,
										} as CSSProperties
									}
									onClick={() => {
										if (videoRef.current) {
											videoRef.current.currentTime =
												time + 1;
											setCurrentTime(time + 1);
										}
									}}
								>
									{title}
								</button>
							);
						})}
					</>
				}
			/>
		</>
	);
}
