import { useContext } from 'react';
import { gql } from '@apollo/client';
import { VisuallyHidden } from 'reakit';

import Blocks from '../../components/Blocks/Blocks';
import HotelBookingToolbar from '../../components/HotelBookingToolbar/HotelBookingToolbar';
import { SettingsContext } from '../../components/AppLayout/AppLayout';

import styles from './PageHotel.module.css';

export type PageHotelProps = GetPage_page_PageHotel;

import { GetPage_page_PageHotel } from '../../queries/__generated__/GetPage';
import useTranslation from 'next-translate/useTranslation';

import {
	AmenitiesList,
	AmenitiesListItem,
	Grid,
	StarRating,
} from '@keahotels/components';
import MainHeader, {
	MainHeaderFragment,
} from '../../components/MainHeader/MainHeader';

export default function PageHotel({
	title,
	blocks,
	id,
	intro,
	header,
	stars,
	highlightedAmenities,
	uri,
}: PageHotelProps) {
	const { hotelSettings } = useContext(SettingsContext);
	const { t } = useTranslation('common');

	return (
		<>
			<VisuallyHidden as="h1">{title}</VisuallyHidden>
			<HotelBookingToolbar />

			{header && (
				<MainHeader {...header}>
					<div
						className={styles.amenitiesListWrap}
						style={{
							paddingBottom:
								header.__typename === 'PromoHeader' &&
								header.ctas.length > 2
									? 60
									: undefined,
						}}
					>
						{!!stars && (
							<StarRating
								className={styles.starRating}
								stars={stars}
								aria-label={t(`starRatingAria_${stars}`, {
									hotelName: hotelSettings?.title,
								})}
							/>
						)}
						{highlightedAmenities && !!highlightedAmenities.length && (
							<AmenitiesList
								direction={
									header.__typename === 'PromoHeader' &&
									header.ctas.length > 2
										? 'cols3'
										: 'row'
								}
								className={styles.amenitiesList}
							>
								{highlightedAmenities.map((a, i) => (
									<AmenitiesListItem
										key={a.text || i}
										{...a}
									/>
								))}
							</AmenitiesList>
						)}
					</div>
					{intro && (
						<div
							className={`text-large-body ${styles.intro}`}
							dangerouslySetInnerHTML={{ __html: intro }}
						/>
					)}
				</MainHeader>
			)}
			<Blocks blocks={blocks} />
		</>
	);
}

export const PageHotelFragment = gql`
	fragment PageHotelFragment on WpItem {
		... on PageHotel {
			header {
				...MainHeaderFragment
			}
			highlightedAmenities {
				icon
				text
			}
			stars
			uri
		}
	}
	${MainHeaderFragment}
`;
