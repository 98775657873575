import { ReactNode } from 'react';

import { CTABox, Eyebrow, Grid } from '@keahotels/components';

import Link from '../Link/Link';

import styles from './PromoHeader.module.css';

export type Promo = {
	eyebrow: string;
	title: string;
	ctaText: string;
	url: string;
	image?: any;
};

export type PromoHeaderProps = {
	promos: Promo[];
	image: ReactNode;
	children: ReactNode;
	overlap?: boolean;
};

export default function PromoHeader({
	promos,
	image,
	overlap = true,
	children,
}: PromoHeaderProps) {
	return (
		<Grid className={styles.wrap}>
			<div
				className={`${styles.header} ${overlap ? styles.overlap : ''}`}
			>
				{image}
			</div>
			{promos?.length ? (
				<div className={styles.promos}>
					{promos.map((promo, i) => (
						<CTABox
							key={i}
							as={promo.url ? Link : 'div'}
							// @ts-ignore
							href={promo.url || undefined}
							size="medium"
							title={promo.title}
							eyebrow={<Eyebrow text={promo.eyebrow} center />}
							image={promo.image}
							ctaText={promo.ctaText}
							notBlurry
						/>
					))}
				</div>
			) : null}
			<div
				className={
					promos?.length > 2
						? styles.children
						: styles.childrenFullWidth
				}
			>
				{children}
			</div>
		</Grid>
	);
}
