import { gql } from '@apollo/client';
import client from '../../apollo-client';
import { BlocksFragment } from '../components/Blocks/Blocks';
import { ImageFragment } from '../components/Image/Image';
import { NeighbourhoodFragment } from '../components/NeighbourhoodSection/NeighbourhoodSection';
import { PageProps } from '../templates/Page/Page';

import { PageActivitiesItemFragment } from '../templates/PageActivitiesItem/PageActivitiesItem';
import { PageDefaultFragment } from '../templates/PageDefault/PageDefault';
import { PageHotelFragment } from '../templates/PageHotel/PageHotel';
import { PageHotelAboutFragment } from '../templates/PageHotelAbout/PageHotelAbout';
import { PageOffersItemFragment } from '../templates/PageOffersItem/PageOffersItem';
import {
	GetPage as GetPageType,
	GetPageVariables,
} from './__generated__/GetPage';

export const TranslationFragment = gql`
	fragment TranslationFragment on Translation {
		id
		lang
		post {
			uri
		}
	}
`;

export const GetPageQuery = gql`
	query GetPage($slug: String!, $locale: String!) {
		page: WpItem(slug: $slug, locale: $locale) {
			id
			title
			intro
			translations {
				...TranslationFragment
			}
			blocks {
				...BlocksFragment
			}
			seo {
				title
				description
				openGraphImage
				keywords
			}
			...PageHotelFragment
			...PageHotelAboutFragment
			...PageActivitiesItemFragment
			...PageOffersItemFragment
			...PageDefaultFragment
			...NeighbourhoodFragment
		}
		WpGlobalInfo {
			redirects {
				path
				to
				type
			}
		}
	}

	${TranslationFragment}
	${BlocksFragment}
	${ImageFragment}
	${PageHotelFragment}
	${PageHotelAboutFragment}
	${PageActivitiesItemFragment}
	${PageOffersItemFragment}
	${PageDefaultFragment}
	${NeighbourhoodFragment}
`;

type QueryProps = {
	slug: string;
	locale: string;
};

export const GetPage = async ({ slug, locale }: QueryProps) => {
	const { data, error, ...rest } = await client
		.query<GetPageType, GetPageVariables>({
			variables: {
				slug,
				locale,
			},
			query: GetPageQuery,
		})
		.catch((error) => {
			console.log('Failed to fetch data for slug ', slug, locale);
			console.log(error.networkError);
			return { data: {}, error };
		});
	if (error) console.log(error);

	if (!(data && 'page' in data && data.page)) {
		if (data && 'WpGlobalInfo' in data) {
			// Next.js does not return en/ or is/ as part of the
			// slug, so we have to add it in order to check if it matches

			const currentPath = [`/${slug}`];

			const redirect = data.WpGlobalInfo.redirects?.find((r) => {
				const pathHasLocalePrefix =
					r.path.startsWith('/en') || r.path.startsWith('/is');

				if (pathHasLocalePrefix) {
					currentPath.push(`/${locale}/${slug}`);
				}

				return currentPath.includes(r.path);
			});

			if (redirect) {
				// we found a redirect!
				return {
					redirect: {
						destination: redirect.to,
						permanent: redirect.type === '301',
					},
				};
			}
		}
		return {
			notFound: true,
			props: {},
		};
	}

	return {
		notFound: false,
		props: {
			...data,
			locale,
			slug,
		} as PageProps,
	};
};
