module.exports = {
	locales: ['is', 'en'],
	defaultLocale: 'en',
	localeDetection: false,
	logBuild: false,
	pages: {
		'*': ['common'],
	},
	loadLocaleFrom: (lang, ns) => {
		// You can use a dynamic import, fetch, whatever. You should
		// return a Promise with the JSON file.
		return import(`./public/locales/${lang}/${ns}.json`).then((m) => {
			return m.default;
		});
	},
};
