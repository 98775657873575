import styles from './HeroHeader.module.css';

import React, { ReactNode, useContext } from 'react';
import { Button, Eyebrow, Grid, StarRating } from '@keahotels/components';

import { ReactComponent as NorthEast } from '@keahotels/assets/icons/NorthEast.svg';
import Link from '../Link/Link';
import useTranslation from 'next-translate/useTranslation';

export type HeaderCTAProps = {
	eyebrow?: string | null;
	title?: string | null;
	buttonText?: string | null;
	content?: ReactNode;
	className?: string | null;
	linkHref?: string;
	onClickHandler?: (e: any) => void;
};

export const HeaderCTA = ({
	eyebrow,
	title,
	buttonText,
	content,
	linkHref,
	className = '',
	onClickHandler = () => {},
}: HeaderCTAProps) => {
	const { t } = useTranslation('common');

	return (
		<Link
			href={linkHref || '#'}
			onClick={onClickHandler}
			className={`${styles.cta} ${className}`}
		>
			<p className={styles.ctaTitle}>
				{title || t('ctaBookBestPriceHere')}
			</p>
			<Eyebrow
				text={eyebrow || t('bestPriceGuarantee')}
				center
				className={styles.ctaEyebrow}
			/>

			{content && <div className={styles.content}>{content}</div>}
			<Button
				icon={<NorthEast aria-hidden />}
				className={styles.ctaButton}
				as="div"
				variant="clear"
			>
				{buttonText || t('bookNow')}
			</Button>
		</Link>
	);
};
export type HeroHeaderProps = {
	image: ReactNode;
	children: ReactNode;
	cta?: HeaderCTAProps;
};
export default function HeroHeader({
	image,
	children,
	cta = { linkHref: '#' },
}: // cta,
HeroHeaderProps) {
	return (
		<Grid>
			<div className={styles.cover}>
				{image}
				<HeaderCTA {...cta} />
			</div>

			<div className={styles.intro}>{children}</div>
		</Grid>
	);
}
