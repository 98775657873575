// import styles from './BlockTitle.module.css';

import { gql } from '@apollo/client';
import { Section, SectionHeading } from '@keahotels/components';
import { ReactNode } from 'react';
import { BlockTitleFragment_BlockTitle } from './__generated__/BlockTitleFragment';

export type BlockTitleProps = {
	children?: ReactNode;
} & BlockTitleFragment_BlockTitle;

export default function BlockTitle({ title, children }: BlockTitleProps) {
	return (
		<Section
			heading={
				title ? <SectionHeading>{title}</SectionHeading> : undefined
			}
		>
			{children}
		</Section>
	);
}

export const BlockTitleFragment = gql`
	fragment BlockTitleFragment on BlockItem {
		... on BlockTitle {
			title
		}
	}
`;
