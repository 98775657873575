import { gql } from '@apollo/client';
import Blocks from '../../components/Blocks/Blocks';
import HotelBookingToolbar from '../../components/HotelBookingToolbar/HotelBookingToolbar';
import PageHeader, {
	PageHeaderFragment,
} from '../../components/PageHeader/PageHeader';
import { GetPage_page_PageDefault } from '../../queries/__generated__/GetPage';

// import styles from './PageDefault.module.css';

export type PageDefaultProps = GetPage_page_PageDefault;

export default function PageDefault({
	title,
	blocks,
	intro,
	header,
}: PageDefaultProps) {
	return (
		<>
			<HotelBookingToolbar />
			{header && (
				<PageHeader
					title={title || ''}
					intro={intro || undefined}
					{...header}
				/>
			)}

			<Blocks blocks={blocks} />
		</>
	);
}

export const PageDefaultFragment = gql`
	fragment PageDefaultFragment on WpItem {
		... on PageDefault {
			header {
				...PageHeaderFragment
			}
		}
	}
	${PageHeaderFragment}
`;
