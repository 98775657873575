import { CSSProperties, Fragment, ReactNode } from 'react';
import styles from './GuestsPicker.module.css';

import { ReactComponent as Plus } from '@keahotels/assets/icons/Plus.svg';
import { ReactComponent as Minus } from '@keahotels/assets/icons/Minus.svg';
import useGlobalState from '../../hooks/useGlobalState';
import useTranslation from 'next-translate/useTranslation';
import { Button } from '@keahotels/components';
type GuestsPickerRowProps = {
	title: ReactNode;
	subtitle?: ReactNode;
	value: ReactNode;
	controls: ReactNode;
	style?: CSSProperties;
};

const GuestPickerRow = ({
	title,
	subtitle,
	value,
	controls,
	style,
}: GuestsPickerRowProps) => (
	<div className={styles.row} style={style}>
		<dt className={styles.rowTitle}>
			<span>{title}</span>
			{subtitle && <span className={styles.subtitle}>{subtitle}</span>}
		</dt>
		<dd>{value}</dd>
		<dd className={styles.controls}>{controls}</dd>
	</div>
);

export type GuestsPickerProps = {
	maxAdults?: number;
	maxChildren?: number;
	maxInfants?: number;
};

export default function GuestsPicker({
	maxAdults,
	maxChildren,
	maxInfants,
}: GuestsPickerProps) {
	const {
		addAdult,
		removeAdult,
		addChild,
		removeChild,
		addInfant,
		removeInfant,
		addRoom,
		removeRoom,
		state,
	} = useGlobalState();

	const { t } = useTranslation('common');
	return (
		<div className={styles.wrap}>
			{state.rooms.map(({ adults, children, infants }, i) => (
				<Fragment key={i}>
					<h3 className={styles.title}>
						{t('Guests')}{' '}
						{state.rooms.length > 1
							? t('roomGroupNr', {
									nr: i + 1,
							  })
							: ''}
						{state.rooms.length > 1 && (
							<button
								onClick={() => removeRoom(i)}
								className={styles.controlButton}
								aria-label={t('removeRoom')}
							>
								<Minus aria-hidden />
							</button>
						)}
					</h3>
					<dl className={styles.optionsList}>
						<GuestPickerRow
							title={
								t('adults').charAt(0).toUpperCase() +
								t('adults').slice(1)
							}
							value={`${adults} ${(adults > 1
								? t('adults')
								: t('adultsValueSingular')
							).toLowerCase()}`}
							controls={
								<>
									<button
										onClick={() => {
											removeAdult(i);
										}}
										className={styles.controlButton}
										aria-disabled={adults === 1}
									>
										<Minus />
									</button>
									<button
										onClick={() => {
											if ((maxAdults || 99) !== adults) {
												addAdult(i);
											}
										}}
										className={styles.controlButton}
										aria-disabled={
											(maxAdults || 99) === adults
										}
									>
										<Plus />
									</button>
								</>
							}
						/>
						<GuestPickerRow
							title={
								t('children_multi').charAt(0).toUpperCase() +
								t('children_multi').slice(1)
							}
							subtitle={t('ageFromTo', {
								from: 3,
								to: 11,
							})}
							value={
								children
									? `${children} ${
											children > 1
												? t('children_multi')
												: t('children')
									  }`
									: t('noChildren')
							}
							controls={
								<>
									<button
										onClick={() => removeChild(i)}
										className={styles.controlButton}
									>
										<Minus />
									</button>

									<button
										onClick={() => {
											if (
												(maxChildren || 99) !==
												children + infants
											) {
												addChild(i);
											}
										}}
										className={styles.controlButton}
										aria-disabled={
											(maxChildren || 99) ===
												children + infants ||
											(state.rooms.length === 1
												? children + infants >=
												  adults * 2
												: children + infants >= 2)
										}
									>
										<Plus />
									</button>

									{/* <button
										onClick={() => addChild(i)}
										className={styles.controlButton}
										disabled={
											state.rooms.length === 1
												? children + infants >=
												  adults * 2
												: children + infants >= 2
										}
									>
										<Plus />
									</button> */}
								</>
							}
						/>
						<GuestPickerRow
							title={t('infants_plural_no_count')}
							subtitle={t('ageFromTo', {
								from: 0,
								to: 2,
							})}
							value={
								infants
									? t(
											infants > 1
												? 'infants_multi'
												: 'infants',
											{
												count: infants,
											},
									  )
									: t('noInfants')
							}
							controls={
								<>
									<button
										onClick={() => removeInfant(i)}
										className={styles.controlButton}
									>
										<Minus />
									</button>

									<button
										onClick={() => {
											if (
												(maxInfants || 99) !==
												children + infants
											) {
												addInfant(i);
											}
										}}
										className={styles.controlButton}
										aria-disabled={
											(maxInfants || 99) ===
												children + infants ||
											(state.rooms.length === 1
												? children + infants >= adults
												: children + infants >= 1)
										}
									>
										<Plus />
									</button>
									{/* <button
										onClick={() => addInfant(i)}
										className={styles.controlButton}
										disabled={
											state.rooms.length === 1
												? children + infants >= adults
												: children + infants >= 1
										}
									>
										<Plus />
									</button> */}
								</>
							}
						/>
					</dl>
				</Fragment>
			))}

			<div className={styles.customAddRoom}>
				{state.rooms.length < 4 ? (
					<>
						{state.rooms.length === 1 && !maxAdults && (
							<p>{t('guestsPairing')}</p>
						)}
						<Button
							variant="clear"
							onClick={addRoom}
							className={styles.controlButton}
						>
							{state.rooms.length === 1
								? t('clickToAddRooms')
								: t('addRoom')}
						</Button>
					</>
				) : (
					<p
						dangerouslySetInnerHTML={{
							__html: t('maxRoomsReached'),
						}}
					/>
				)}
			</div>
		</div>
	);
}
