import { gql, useQuery } from '@apollo/client';
import { addDays } from 'date-fns';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import client from '../../../apollo-client';
import { Currency, Language } from '../../../__generated__/globalTypes';
import dateToString from '../../utils/dateToString';
import hotelData from '../../utils/hotelData';
import { useSettings } from '../AppLayout/AppLayout';
import {
	GetAvailableRooms,
	GetAvailableRoomsVariables,
	GetAvailableRooms_Availability_results as ResultType,
	GetAvailableRooms_Availability_results_rooms,
} from './__generated__/GetAvailableRooms';

export const BookingSearchQuery = gql`
	query GetAvailableRooms(
		$hotels: [String!]
		$from: String!
		$to: String!
		$lang: Language!
		$currency: Currency!
		$rooms: [RoomsQuery!]!
		$offer: Offer
		$promoCode: String
		$offerKey: String
	) {
		Availability(
			hotels: $hotels
			dateFrom: $from
			dateTo: $to
			lang: $lang
			currency: $currency
			rooms: $rooms
			offer: $offer
			promoCode: $promoCode
			offerKey: $offerKey
		) {
			results {
				id
				title
				bookingKey
				hotelId
				hotel {
					id: originalId
					title
				}
				rooms {
					roomId
					title
					content {
						title
						about
						bedSize
						equipment {
							title
						}
						id
						images {
							alt
							caption
							url
						}
						maxPersons
						roomSize
					}
					rates {
						includesExtraBed
						rateId
						availability
						breakfast
						refundable
						description
						discount {
							amount
							description
						}
						price
						currency
						cancelation {
							policy
							cancelBefore
						}
						policies {
							rate
							payment
							cancelation
						}
						availabilityCalendar {
							availability
							date
							price
							priceWithDiscount
						}
					}
				}
			}
		}
	}
`;

export type BookingSearchQuery = {
	from?: string;
	to?: string;
	rooms?: string[] | string;
	currency?: string;
	hotels?: string;
	promo: string;
};

export default function useBookingSearch() {
	const { query, locale = 'en' } = useRouter();
	const { hotelSettings } = useSettings();

	const {
		from: qFrom,
		to: qTo,
		rooms: qRooms = [],
		currency = 'EUR',
		promo: promoCode,
	} = query as BookingSearchQuery;

	const lang: Language = Language[locale.toUpperCase()] || Language.EN;
	const curr: Currency = Currency[currency.toUpperCase()] || Currency.EUR;

	// console.log({ query });
	const rooms = new Array<string>()
		.concat(qRooms)
		.map((r) => r.split(',').map((n) => parseInt(n)))
		.map(([adults, children, infants]) => ({ adults, children, infants }));

	const from = qFrom || dateToString(new Date());
	const to = qTo || dateToString(addDays(new Date(), 2));

	const hotelBonId = hotelSettings?.bonId?.toString();
	const hotelName = hotelSettings?.title || 'All';

	const queryResult = useQuery<GetAvailableRooms, GetAvailableRoomsVariables>(
		BookingSearchQuery,
		{
			variables: {
				hotels: hotelBonId ? [hotelBonId] : null,
				from,
				to,
				rooms,
				currency: curr,
				lang,
				promoCode: promoCode || '',
			},
			client,
		},
	);

	const { data } = queryResult;

	useEffect(() => {
		const res =
			data?.Availability?.results?.filter((r): r is ResultType => !!r) ||
			[];

		// Add impressions to gtag
		if (res && res.length) {
			// Todo split rooms by rate to send all rates shown
			const rooms = res.reduce(
				(rooms, r) => rooms.concat(r.rooms),
				[] as GetAvailableRooms_Availability_results_rooms[],
			);
			const googlePlaceId = hotelSettings?.originalId
				? hotelData[hotelSettings?.originalId]
				: undefined;

			gtag('event', 'view_item_list', {
				item_list_id: 'availability_search_' + hotelBonId || 'all',
				item_list_name: hotelName + ' availability search',
				affiliation: 'Website',
				items: rooms.map((r, i) => ({
					item_id: r.roomId,
					item_name: r.title,
					coupon: promoCode,
					currency: curr,
					discount: r.rates[0]?.discount?.amount || 0,
					index: i,
					location_id: googlePlaceId,
					price: r.rates[0]?.price,
					quantity: 1,
				})),
			});
		}
	}, [
		curr,
		data,
		hotelBonId,
		hotelName,
		hotelSettings?.originalId,
		promoCode,
	]);

	return queryResult;
}

// // Add impressions to datalayer
// if (res && res.length) {
// 	window.dataLayer && window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
// 	window.dataLayer &&
// 		window.dataLayer.length &&
// 		window.dataLayer.push({
// 			ecommerce: {
// 				currencyCode: res[0].rooms[0].rates[0]?.currency,
// 				impressions: res.map((item, i) => ({
// 					name: `${item.hotel?.title} – ${item.title}`,
// 					price: item.rooms
// 						.map((room) => room.rates[0]?.price)
// 						.reduce(
// 							(total, price) =>
// 								(total || 0) + (price || 0),
// 						),
// 					position: i + 1,
// 				})),
// 			},
// 		});
// }
