
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { StrictMode, useEffect } from 'react';
import NextApp from 'next/app';
import Head from 'next/head';
import { Provider } from 'reakit';

import type { AppProps, AppContext } from 'next/app';

import AppLayout from '../src/components/AppLayout/AppLayout';

// Import global styles
import '@keahotels/assets/styles/base.css';
import '@reach/skip-nav/styles.css';

// Import component styles
import '@keahotels/components/dist/index.css';
import getGlobalData from '../src/hooks/useGlobalDataQuery';
import Script from 'next/script';

const gtagDebug = false;

function App({ Component, pageProps }: AppProps) {
	const Content = Component as any;

	return (
		<StrictMode>
			<Head>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1"
				/>

				{/* <script
					dangerouslySetInnerHTML={{
						__html: `
						window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);}
						(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
						new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
						j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
						'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
						})(window,document,'script','dataLayer','GTM-T34FZ42');`,
					}}
				/> */}
				<script
					defer
					async
					type="text/javascript"
					src="https://onboard.triptease.io/bootstrap.js?integrationId=01D0Y3EF1215MGTD5HVKEK4BDC"
				></script>
			</Head>
			<Script
				src="https://www.googletagmanager.com/gtag/js?id=GTM-T34FZ42"
				strategy="afterInteractive"
			/>
			<Script id="google-analytics" strategy="afterInteractive">
				{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'GTM-T34FZ42'${
				process.env.NODE_ENV !== 'production' && gtagDebug
					? ", { 'debug_mode':true }"
					: ''
			});
        `}
			</Script>
			<Provider>
				<AppLayout {...pageProps}>
					<Content {...pageProps} />
				</AppLayout>
			</Provider>
		</StrictMode>
	);
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.

App.getInitialProps = async (appContext: AppContext) => {
	const { query, locale = 'en', asPath } = appContext.router;

	// We can find the hotel slug as either the hotel prop
	// when the route matches [hotel]/book or as the first
	// entry in the more general [...slug] route. If we can't
	// find the hotel (or the slug picks something else up)
	// won't get the hotelSettings, which is fine
	const hotel =
		query.hotel && typeof query.hotel === 'string'
			? query.hotel
			: query.slug?.[0] || '';

	// calls page's `getInitialProps` and fills `appProps.pageProps`
	const appPropsPromise = NextApp.getInitialProps(appContext);

	const { data: globalData } = await getGlobalData({
		hotel,
		locale,
	}).catch((e) => {
		console.error('GQL error when fetching global data for ', asPath);
		console.error('Hotel: ', hotel);
		return {
			data: {
				hotelSettings: {},
				globalSettings: {},
			},
		};
	});

	const appProps = await appPropsPromise;

	const props = {
		...appProps,
		pageProps: {
			hotelSettings: globalData?.hotelSettings,
			globalSettings: globalData?.globalSettings,
			...appProps.pageProps,
		},
	};

	// console.log({ hotel });
	// console.log(globalData);

	return props;
};

const __Page_Next_Translate__ = App;
// const __Page_Next_Translate__ = App;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  