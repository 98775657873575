import { Fragment, ReactNode } from 'react';
import { motion, useReducedMotion } from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';

import {
	BookingSelect,
	Button,
	Eyebrow,
	useBookingSelectState,
} from '@keahotels/components';

import { ReactComponent as CircleGuilsingRight } from '@keahotels/assets/icons/CircleGuilsingRight.svg';
import { ReactComponent as MenuMap } from '@keahotels/assets/images/menu-map.svg';
import { ReactComponent as ExternalLink } from '@keahotels/assets/icons/ExternalLink.svg';

import { useSettings } from '../AppLayout/AppLayout';
import Link from '../Link/Link';

import styles from './LocationMenu.module.css';
// import {
// 	GetGlobalData_globalSettings_hotels_items as HotelItems,
// 	GetGlobalData_globalSettings_hotels,
// } from '../../hooks/__generated__/GetGlobalData';
import isNotNull from '../../utils/isNotNull';

const container = {
	hidden: { opacity: 0 },
	show: {
		opacity: 1,
		transition: {
			staggerChildren: 0.03,
		},
	},
};

const item = {
	hidden: { opacity: 0, transform: 'translateY(-10px)' },
	show: { opacity: 1, transform: 'translateY(0px)' },
};
const rmItem = {
	hidden: { opacity: 0 },
	show: { opacity: 1 },
};

const MenuTitle = (props: { children?: ReactNode }) => {
	return (
		<Eyebrow
			as={motion.dt}
			variants={item}
			{...props}
			className={styles.menuTitle}
		/>
	);
};
const LinkMenuItem = ({
	id,
	children,
	href,
	onClick,
	isExternal,
}: {
	id: number;
	children: ReactNode;
	href: string;
	isExternal?: boolean;
	onClick?: (hotelId: number) => void;
}) => {
	const rm = useReducedMotion();
	return (
		<motion.dd variants={rm ? rmItem : item} className={styles.menuItem}>
			<Link
				href={href}
				target={isExternal ? '_blank' : undefined}
				onClick={() => onClick?.(id)}
				className={styles.menuItemButton}
			>
				{children}
				{isExternal && (
					<span className={styles.external}>
						<ExternalLink />
					</span>
				)}
			</Link>
		</motion.dd>
	);
};

const ButtonMenuItem = ({
	id,
	children,
	href,
	onClick,
}: {
	id: number;
	children: ReactNode;
	href: string;
	isExternal?: boolean;
	onClick?: (hotelId: number) => void;
}) => {
	const rm = useReducedMotion();
	return (
		<motion.dd variants={rm ? rmItem : item} className={styles.menuItem}>
			<button
				onClick={() => onClick?.(id)}
				className={styles.menuItemButton}
			>
				{children}
			</button>
		</motion.dd>
	);
};

export const HotelLocationsList = ({
	isVisible,
	onClickItem,
	asButton,
}: {
	isVisible: boolean;
	onClickItem?: (id: number) => void;
	asButton?: boolean;
}) => {
	const { globalSettings } = useSettings();
	const { t, lang } = useTranslation('common');
	const MenuItem = asButton ? ButtonMenuItem : LinkMenuItem;
	return (
		<motion.dl
			variants={container}
			initial="hidden"
			animate={isVisible ? 'show' : 'hidden'}
			className={styles.menu}
		>
			{globalSettings?.hotels?.map(({ title, items }, i) => (
				<Fragment key={`${title}_${i}`}>
					<MenuTitle>{title}</MenuTitle>
					{items.filter(isNotNull).map((item) => (
						<MenuItem
							id={item.id}
							onClick={
								[9879].includes(item.id)
									? undefined
									: onClickItem
							}
							href={
								[9879].includes(item.id)
									? lang === 'is'
										? `https://grimsborgir.is`
										: `https://grimsborgir.com`
									: item.uri
							}
							key={item.title}
							isExternal={[9879].includes(item.id)}
						>
							{item.title}
						</MenuItem>
					))}
				</Fragment>
			))}
		</motion.dl>
	);
};

export type LocationMenuProps = {
	className?: string;
};

export default function LocationMenu({ className }: LocationMenuProps) {
	const { t } = useTranslation('common');

	const dialog = useBookingSelectState({ modal: false });
	return (
		<BookingSelect
			className={`${styles.wrap} ${className}`}
			buttonLabel="See our different locations"
			controller={dialog}
			value={
				<Button
					className={styles.button}
					as="span"
					variant="clear"
					size="small"
					icon={<CircleGuilsingRight />}
				>
					{t('ourHotels')}
				</Button>
			}
		>
			<nav aria-label="Hotel Menu" className={styles.modalWrap}>
				<HotelLocationsList
					isVisible={dialog.visible}
					onClickItem={dialog.hide}
				/>
				<aside className={styles.aside}>
					<Eyebrow as="h2">Our hotels</Eyebrow>
					<p>{t('locationMenuText')}</p>
					<MenuMap
						aria-hidden
						className={`${styles.asideMap} noPathColor`}
					/>
				</aside>
			</nav>
		</BookingSelect>
	);
}
