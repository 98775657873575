import { useSettings } from '../AppLayout/AppLayout';
import HotelLogo from '../HotelLogo/HotelLogo';

import { ReactComponent as Agent } from '@keahotels/assets/icons/Agent.svg';
import { ReactComponent as Facebook } from '@keahotels/assets/icons/Facebook.svg';
import { ReactComponent as Instagram } from '@keahotels/assets/icons/Instagram.svg';
import { ReactComponent as Tripadvisor } from '@keahotels/assets/icons/Tripadvisor.svg';
import { ReactComponent as Twitter } from '@keahotels/assets/icons/Twitter.svg';

import styles from './AppFooter.module.css';
import { VisuallyHidden } from 'reakit/VisuallyHidden';
import {
	BreakdownList,
	BreakdownListItem,
	Button,
	Eyebrow,
	Grid,
} from '@keahotels/components';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import Link from '../Link/Link';

export type AppFooterProps = {};

export default function AppFooter({}: AppFooterProps) {
	const { t } = useTranslation('common');
	const { globalSettings, hotelSettings } = useSettings();
	const tripAdvisorUrl =
		hotelSettings?.tripAdvisorUrl || globalSettings?.tripAdvisorUrl;
	const facebookUrl =
		hotelSettings?.facebookUrl || globalSettings?.facebookUrl;
	const twitterUrl = hotelSettings?.twitterUrl || globalSettings?.twitterUrl;
	const instagramUrl =
		hotelSettings?.instagramUrl || globalSettings?.instagramUrl;
	const reg = hotelSettings?.reg || globalSettings?.reg;
	const phone = hotelSettings?.phone || globalSettings?.phone;
	const fax = hotelSettings?.fax || globalSettings?.fax;
	const email = hotelSettings?.email || globalSettings?.email;
	const address = hotelSettings?.address || globalSettings?.headOfficeAddress;

	return (
		<Grid as="footer" className={styles.wrap}>
			<Link
				href={hotelSettings ? hotelSettings.uri : '/'}
				className={styles.logo}
			>
				<HotelLogo />
				<VisuallyHidden>
					{hotelSettings?.title || 'Keahotels'}
				</VisuallyHidden>
			</Link>
			<ul className={styles.socialLinks}>
				{tripAdvisorUrl && (
					<li>
						<a href={tripAdvisorUrl || '#'}>
							<VisuallyHidden>Trip Advisor</VisuallyHidden>
							<Tripadvisor />
						</a>
					</li>
				)}
				{facebookUrl && (
					<li>
						<a href={facebookUrl || '#'}>
							<VisuallyHidden>Facebook</VisuallyHidden>
							<Facebook />
						</a>
					</li>
				)}
				{twitterUrl && (
					<li>
						<a href={twitterUrl || '#'}>
							<VisuallyHidden>Twitter</VisuallyHidden>
							<Twitter />
						</a>
					</li>
				)}
				{instagramUrl && (
					<li>
						<a href={instagramUrl || '#'}>
							<VisuallyHidden>Instagram</VisuallyHidden>
							<Instagram />
						</a>
					</li>
				)}
			</ul>
			<dl className={styles.infoList}>
				<div>
					<Eyebrow as="dt">{t('reg')}</Eyebrow>
					<dd>{reg}</dd>
				</div>
				<div>
					<Eyebrow as="dt">{t('phone')}</Eyebrow>
					<dd>
						<a href={`tel:${phone}`}>{phone}</a>
					</dd>
				</div>
				<div>
					<Eyebrow as="dt">
						{hotelSettings?.address
							? t('address')
							: t('headOffice')}
					</Eyebrow>
					<dd>{address}</dd>
				</div>
				<div>
					<Eyebrow as="dt">{t('fax')}</Eyebrow>
					<dd>{fax}</dd>
				</div>
				<div>
					{!hotelSettings?.address ? (
						<>
							<Eyebrow as="dt">{t('salesOffice')}</Eyebrow>
							<dd>{globalSettings?.salesOfficeAddress}</dd>
						</>
					) : null}
				</div>
				<div>
					<Eyebrow as="dt">{t('email')}</Eyebrow>
					<dd>
						<a href={`mailto:${email}`}>{email}</a>
					</dd>
				</div>
			</dl>
			<Button
				variant="clear"
				size="small"
				icon={<Agent />}
				as="a"
				href="https://ta.keahotels.is/login"
				target="_blank"
				rel="noopener"
				className={styles.agentLink}
			>
				Agent portal
			</Button>
		</Grid>
	);
}
