import { useEffect } from 'react';

export default function useBodyClassName(className?: string) {
	useEffect(() => {
		if (className) {
			document.body.classList.add(className);
		} else {
			document.body.className = '';
		}

		return () => {
			document.body.className = '';
		};
	}, [className]);
}
