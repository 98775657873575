import useTranslation from 'next-translate/useTranslation';
import { Currency } from '../../__generated__/globalTypes';

type UseFormatCurrencyArgs = {
	currency?: Currency;
	maximumFractionDigits?: number;
};
export default function useFormatCurrency({
	currency = Currency.EUR,
	maximumFractionDigits = 2,
}: UseFormatCurrencyArgs) {
	const { lang } = useTranslation('common');

	const formatter = new Intl.NumberFormat(lang === 'is' ? 'de' : lang, {
		style: 'currency',
		currency,
		maximumFractionDigits,
		minimumFractionDigits: 0,
	});
	return formatter;
}
