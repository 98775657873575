// import styles from './MainHeaderImage.module.css';
import Image from '../Image/Image';
import { imageSizes } from './MainHeader';

import { MainHeaderFragment_HeroHeader_slider_MainHeaderImage } from './__generated__/MainHeaderFragment';

export default function MainHeaderImage({
	title,
	image,
	headerType,
}: MainHeaderFragment_HeroHeader_slider_MainHeaderImage & {
	headerType: keyof typeof imageSizes;
}) {
	return image ? (
		<Image
			{...imageSizes[headerType]}
			{...image}
			caption={title || image?.caption}
			priority
			id={undefined}
			captionPosition="inside"
		/>
	) : null;
}
