import { gql } from '@apollo/client';
import { ReactNode, useContext } from 'react';
import HeroHeader from '../HeroHeader/HeroHeader';
import Image, { ImageFragment } from '../Image/Image';
import { MainHeaderFragment as MainHeaderFragmentType } from './__generated__/MainHeaderFragment';
import PromoHeader from '../PromoHeader/PromoHeader';
import useGlobalState, { useGetCurrency } from '../../hooks/useGlobalState';
import { SettingsContext } from '../AppLayout/AppLayout';
import { format, addDays } from 'date-fns';
import MainHeaderVideoSlider from './MainHeaderVideoSlider';
import MainHeaderImage from './MainHeaderImage';
import MainHeaderImageSlider from './MainHeaderImageSlider';

export const imageSizes = {
	PromoHeader: {
		sizes: '(max-width: 800px) 1106px, 100vw',
		aspectRatio: 'landscape' as 'landscape',
	},
	HeroHeader: {
		sizes: '(max-width: 1400px) 1340px, 95vw',
		aspectRatio: 'header-landscape' as 'header-landscape',
	},
};

export default function MainHeader({
	children,
	...props
}: MainHeaderFragmentType & { children?: ReactNode }) {
	const { state } = useGlobalState();
	const { hotelSettings } = useContext(SettingsContext);

	const hotelSlug = hotelSettings?.uri.replace(/\/$/, '') || '';

	const ctaDateFrom = state.dates.from || addDays(new Date(), 1);
	const ctaDateTo = state.dates.to || addDays(new Date(), 2);
	const rooms = state.rooms
		? `${state.rooms
				.map(
					(item) => `${item.adults},${item.children},${item.infants}`,
				)
				.join('&rooms=')}`
		: '2,0,0';

	const currency = useGetCurrency();

	const fallbackLinkHref = hotelSlug
		? `${hotelSlug}/book?from=${format(
				ctaDateFrom,
				'yyyy-MM-dd',
		  )}&to=${format(
				ctaDateTo,
				'yyyy-MM-dd',
		  )}&rooms=${rooms}&currency=${currency}`
		: '#select-hotel';

	let slider: ReactNode = null;
	if (!props.slider) {
	} else if (props.slider?.__typename === 'MainHeaderImage') {
		slider = (
			<MainHeaderImage {...props.slider} headerType={props.__typename} />
		);
	} else if (props.slider?.__typename === 'MainHeaderImageSlider') {
		slider = (
			<MainHeaderImageSlider
				{...props.slider}
				headerType={props.__typename}
			/>
		);
	} else {
		slider = (
			<MainHeaderVideoSlider
				{...props.slider}
				headerType={props.__typename}
			/>
		);
	}

	if (props.__typename === 'HeroHeader') {
		const cta = {
			...props.cta,
			linkHref:
				(props.cta?.linkType === 'internal'
					? props.cta.page?.uri
					: props.cta?.url) || fallbackLinkHref,
		};
		return (
			<HeroHeader image={slider} cta={cta}>
				{children}
			</HeroHeader>
		);
	}

	const promos = props.ctas?.map((item) => {
		return {
			eyebrow: item.eyebrow || '',
			title: item.title || '',
			ctaText: item.buttonText || '',
			url:
				(item.linkType === 'internal' ? item.page?.uri : item.url) ||
				fallbackLinkHref,
			image: item.image ? (
				<Image
					src={`${process.env.NEXT_PUBLIC_IMAGE_URL}${item.image.url}`}
					aspectRatio="landscape"
					width={391}
					height={199}
					priority
				/>
			) : null,
		};
	});

	return (
		<PromoHeader promos={promos} image={slider}>
			{children}
		</PromoHeader>
	);
}

export const MainHeaderFragment = gql`
	fragment MainHeaderFragment on MainHeader {
		slider {
			... on MainHeaderImage {
				image {
					...ImageFragment
				}
				title
			}
			... on MainHeaderImageSlider {
				images {
					title
					image {
						...ImageFragment
					}
				}
			}
			... on MainHeaderVideo {
				video {
					uri
				}
				keyframes {
					title
					time
				}
				image {
					...ImageFragment
				}
			}
		}
		... on HeroHeader {
			cta {
				title
				eyebrow
				buttonText
				linkType
				page {
					uri
				}
				url
			}
		}
		... on PromoHeader {
			ctas {
				title
				eyebrow
				buttonText
				linkType
				page {
					uri
				}
				url
				image {
					...ImageFragment
				}
			}
		}
	}
	${ImageFragment}
`;
