import { gql } from '@apollo/client';

import Blocks from '../../components/Blocks/Blocks';
import HotelBookingToolbar from '../../components/HotelBookingToolbar/HotelBookingToolbar';

import { GetPage_page_PageHotelAbout } from '../../queries/__generated__/GetPage';
import PageHeader, {
	PageHeaderFragment,
} from './../../components/PageHeader/PageHeader';
// import styles from './PageHotelAbout.module.css';

export type PageHotelAboutProps = GetPage_page_PageHotelAbout;

export default function PageHotelAbout({
	title,
	blocks,
	intro,
	header,
}: PageHotelAboutProps) {
	return (
		<>
			<HotelBookingToolbar />
			{header && (
				<PageHeader
					title={title || ''}
					intro={intro || undefined}
					{...header}
				/>
			)}

			<Blocks blocks={blocks} />
		</>
	);
}

export const PageHotelAboutFragment = gql`
	fragment PageHotelAboutFragment on WpItem {
		... on PageHotelAbout {
			header {
				...PageHeaderFragment
			}
		}
	}
	${PageHeaderFragment}
`;
