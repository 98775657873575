import { gql } from '@apollo/client';
import { CTABox, DefaultHeader, Eyebrow, Grid } from '@keahotels/components';
import React from 'react';

import Blocks from '../../components/Blocks/Blocks';
import PageHeader, {
	PageHeaderFragment,
} from '../../components/PageHeader/PageHeader';
import HotelBookingToolbar from '../../components/HotelBookingToolbar/HotelBookingToolbar';
import TwinHeader, {
	TwinHeaderFragment,
} from '../../components/TwinHeader/TwinHeader';
import { GetPage_page_PageHotelActivitiesItem } from '../../queries/__generated__/GetPage';

export type PageActivitiesItemProps = GetPage_page_PageHotelActivitiesItem;

export default function PageActivitiesItem({
	title,
	intro,
	blocks,
	header,
}: PageActivitiesItemProps) {
	return (
		<>
			<HotelBookingToolbar />

			{header && (
				<PageHeader
					{...header}
					title={title || ''}
					intro={intro || undefined}
				/>
			)}

			<Blocks blocks={blocks} />
		</>
	);
}

export const PageActivitiesItemFragment = gql`
	fragment PageActivitiesItemFragment on WpItem {
		... on PageHotelActivitiesItem {
			header {
				...PageHeaderFragment
			}
		}
	}
	${PageHeaderFragment}
`;
