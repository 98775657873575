/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Currency {
  EUR = "EUR",
  ISK = "ISK",
}

export enum ImageAspectRatio {
  LANDSCAPE = "LANDSCAPE",
  PORTRAIT = "PORTRAIT",
  SQUARE = "SQUARE",
}

export enum Language {
  EN = "EN",
  IS = "IS",
}

export interface Offer {
  userCountry?: string | null;
  src: number;
}

export interface RoomsQuery {
  adults: number;
  children?: number | null;
  infants?: number | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
