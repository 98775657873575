import { Button, Grid } from '@keahotels/components';
import useLocalStorage from '../../hooks/useLocalStorage';

import { ReactComponent as Check } from '@keahotels/assets/icons/Check.svg';

import styles from './CookieBanner.module.css';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';

export default function CookieBanner() {
	const { t } = useTranslation('common');
	const [hasAcceptedCookies, setHasAcceptedCookies] = useLocalStorage<
		boolean | null
	>('accept_cookies', null);

	const [hasLoaded, setHasLoaded] = useState(false);
	useEffect(() => setHasLoaded(true), []);

	const acceptCookies = () => setHasAcceptedCookies(true);
	// const rejectCookies = () => setHasAcceptedCookies(false);
	return hasLoaded && hasAcceptedCookies === null ? (
		<Grid className={styles.wrap}>
			<div className={styles.banner}>
				<p>{t('cookieBannerDescription')}</p>
				<Button icon={<Check />} onClick={acceptCookies}>
					{t('cookieBannerAccept')}
				</Button>
				{/* <Button variant="clear" onClick={rejectCookies}>
					{t('cookieBannerReject')}
				</Button> */}
			</div>
		</Grid>
	) : null;
}
