import ho from './HotelOverwrites.module.css';

export const hotelClassNames = {
	76: ho.Apotek,
	78: ho.HotelBorg,
	80: ho.ReykjavikLights,
	86: ho.HotelKEA,
	37: ho.Sand,
	74: ho.Skuggi,
	82: ho.Storm,
	84: ho.HotelKatla,
	5437: ho.HotelSiglo,
	5974: ho.SaltGuesthouse,
	9879: ho.Grimsborgir,
};

export default function getHotelClassName(id?: number | null) {
	return id && hotelClassNames[id] ? hotelClassNames[id] : undefined;
}
