import { Draft, Immutable } from 'immer';
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import differenceInDays from 'date-fns/differenceInDays';
import useReducerWithMiddleware from './useReducerWithMiddleware';
import { useRouter } from 'next/router';
import { parseISO } from 'date-fns';

import { Currency } from '../../__generated__/globalTypes';
import { SetCartMutation_SetCart_cart } from './../components/BookingSearchResults/__generated__/SetCartMutation';
import { CheckoutMutation_Checkout_booking as CheckoutBooking } from '../templates/PaymentPage/__generated__/CheckoutMutation';
import useTranslation from 'next-translate/useTranslation';
import { toNumber } from 'lodash';

export type Addon = {
	id: string;
	quantity: number;
};

export type DateRange = {
	from: Date | null;
	to: Date | null;
};

export type GuestCount = {
	adults: number;
	children: number;
	infants: number;
};

export type LocaleType = 'en' | 'is';

export type Booking = SetCartMutation_SetCart_cart;

const INITIAL_PROMO_CODE = '';
const storeStateForDays = 1;

type GlobalState = {
	dates: DateRange;
	rooms: GuestCount[];
	currency: Currency | null;
	currentBooking: Booking | null;
	// When the booking goes through we need to save the bookingId
	// so we can fetch the booking on the thank you page
	completedBooking: CheckoutBooking | null;
	lastUpdated: Date;
	selectedHotels: number[];
	promoCode?: string;
};
export type State = Immutable<GlobalState>;

const initialState: GlobalState = {
	dates: {
		from: null,
		to: null,
	},
	rooms: [
		{
			adults: 2,
			children: 0,
			infants: 0,
		},
	],
	currency: null,
	currentBooking: null,
	completedBooking: null,
	lastUpdated: new Date(),
	selectedHotels: [],
	promoCode: INITIAL_PROMO_CODE,
};

enum ActionType {
	UpdateDates,
	SetRooms,
	AddAdult,
	AddChild,
	AddInfant,
	RemoveAdult,
	RemoveChild,
	RemoveInfant,
	AddRoom,
	RemoveRoom,
	AddAddon,
	RemoveAddon,
	UpdateCurrency,
	UpdateLocale,
	ToggleSelectedHotel,
	Reset,
	CreateBooking,
	CompleteBooking,
	InitState,
	UpdatePromoCode,
}

const createAction =
	<A, P = undefined>(type: A) =>
	(payload: P) => ({
		type,
		payload,
	});

const uDates = createAction<ActionType.UpdateDates, Partial<DateRange>>(
	ActionType.UpdateDates,
);

const sRooms = createAction<ActionType.SetRooms, GuestCount[]>(
	ActionType.SetRooms,
);

const aAdult = createAction<ActionType.AddAdult, number>(ActionType.AddAdult);
const rAdult = createAction<ActionType.RemoveAdult, number>(
	ActionType.RemoveAdult,
);
const aChild = createAction<ActionType.AddChild, number>(ActionType.AddChild);
const rChild = createAction<ActionType.RemoveChild, number>(
	ActionType.RemoveChild,
);
const aInfant = createAction<ActionType.AddInfant, number>(
	ActionType.AddInfant,
);
const rInfant = createAction<ActionType.RemoveInfant, number>(
	ActionType.RemoveInfant,
);

const aRoom = createAction<ActionType.AddRoom>(ActionType.AddRoom);
const rRoom = createAction<ActionType.RemoveRoom, number>(
	ActionType.RemoveRoom,
);
const uCurrency = createAction<ActionType.UpdateCurrency, Currency>(
	ActionType.UpdateCurrency,
);

const uLocale = createAction<ActionType.UpdateLocale, LocaleType>(
	ActionType.UpdateLocale,
);
const aAddon = createAction<ActionType.AddAddon, Addon>(ActionType.AddAddon);

const resetState = createAction<ActionType.Reset>(ActionType.Reset);
export type CreateBooking = Omit<Booking, 'status'>;
const cBooking = createAction<ActionType.CreateBooking, CreateBooking>(
	ActionType.CreateBooking,
);
const complBooking = createAction<ActionType.CompleteBooking, CheckoutBooking>(
	ActionType.CompleteBooking,
);
const iState = createAction<ActionType.InitState, GlobalState>(
	ActionType.InitState,
);
const tHotel = createAction<ActionType.ToggleSelectedHotel, number>(
	ActionType.ToggleSelectedHotel,
);
const uPromoCode = createAction<ActionType.UpdatePromoCode, string>(
	ActionType.UpdatePromoCode,
);

type Action =
	| ReturnType<typeof uDates>
	| ReturnType<typeof sRooms>
	| ReturnType<typeof aAdult>
	| ReturnType<typeof rAdult>
	| ReturnType<typeof aChild>
	| ReturnType<typeof rChild>
	| ReturnType<typeof aInfant>
	| ReturnType<typeof rInfant>
	| ReturnType<typeof aRoom>
	| ReturnType<typeof rRoom>
	| ReturnType<typeof uCurrency>
	| ReturnType<typeof uLocale>
	| ReturnType<typeof resetState>
	| ReturnType<typeof aAddon>
	| ReturnType<typeof cBooking>
	| ReturnType<typeof complBooking>
	| ReturnType<typeof iState>
	| ReturnType<typeof tHotel>
	| ReturnType<typeof uPromoCode>;

function reducer(draft: Draft<State>, action: Action) {
	draft.lastUpdated = new Date();
	// if (
	// 	action.type === ActionType.CompleteBooking ||
	// 	action.type === ActionType.InitState ||
	// 	action.type === ActionType.CreateBooking
	// ) {
	// console.log({ action });
	// }

	switch (action.type) {
		case ActionType.Reset:
			return initialState;
		case ActionType.UpdateDates:
			draft.dates = {
				...draft.dates,
				...action.payload,
			};
			break;
		case ActionType.SetRooms:
			draft.rooms = action.payload;
			break;
		case ActionType.AddAdult:
			if (draft.rooms[action.payload])
				draft.rooms[action.payload].adults++;
			break;
		case ActionType.AddChild:
			if (draft.rooms[action.payload])
				draft.rooms[action.payload].children++;
			break;
		case ActionType.AddInfant:
			if (draft.rooms[action.payload])
				draft.rooms[action.payload].infants++;
			break;
		case ActionType.RemoveAdult:
			if (draft.rooms[action.payload].adults) {
				draft.rooms[action.payload].adults = Math.max(
					draft.rooms[action.payload].adults - 1,
					1,
				);
			}
			break;
		case ActionType.RemoveChild:
			if (draft.rooms[action.payload]) {
				draft.rooms[action.payload].children = Math.max(
					draft.rooms[action.payload].children - 1,
					0,
				);
			}
			break;
		case ActionType.RemoveInfant:
			if (draft.rooms[action.payload]) {
				draft.rooms[action.payload].infants = Math.max(
					draft.rooms[action.payload].infants - 1,
					0,
				);
			}
			break;
		case ActionType.AddRoom:
			draft.rooms.push({ adults: 2, children: 0, infants: 0 });
			break;
		case ActionType.RemoveRoom:
			draft.rooms.splice(action.payload, 1);
			break;
		case ActionType.UpdateCurrency:
			draft.currency = action.payload;
			break;
		case ActionType.CreateBooking:
			if (action.payload) {
				draft.currentBooking = {
					...action.payload,
				};
			}
			break;
		case ActionType.CompleteBooking:
			draft.completedBooking = action.payload;
			draft.currentBooking = null;
			break;
		case ActionType.InitState:
			draft.dates = {
				from: action.payload?.dates?.from
					? new Date(action.payload.dates.from)
					: null,
				to: action.payload?.dates?.to
					? new Date(action.payload.dates.to)
					: null,
			};
			draft.lastUpdated = new Date(action.payload.lastUpdated);
			draft.currency = action.payload.currency;
			draft.currentBooking = action.payload.currentBooking;
			draft.completedBooking = action.payload.completedBooking;
			draft.rooms = action.payload.rooms;
			// draft.selectedHotels = action.payload.selectedHotels || [];
			break;
		case ActionType.ToggleSelectedHotel:
			draft.selectedHotels = [action.payload];
			// const index = draft.selectedHotels.indexOf(action.payload);

			// if (index > -1) {
			// 	draft.selectedHotels.splice(index, 1);
			// } else {
			// 	draft.selectedHotels.push(action.payload);
			// }

			// case ActionType.AddAddon:
			// 	const hasAddon = draft.addons.find(
			// 		(a) => a.id === action.payload.id,
			// 	);
			// 	if (hasAddon) {
			// 		hasAddon.quantity = hasAddon.quantity + action.payload.quantity;
			// 	} else {
			// 		draft.addons.push(action.payload);
			// 	}
			break;
		case ActionType.UpdatePromoCode:
			draft.promoCode = action.payload;
			break;
	}
}

export const StateContext = createContext({
	state: initialState,
	updateDates(dates: DateRange) {
		return;
	},
	setRooms(rooms: GuestCount[]) {
		return;
	},
	addAdult(room: number) {
		return;
	},
	removeAdult(room: number) {
		return;
	},
	addChild(room: number) {
		return;
	},
	removeChild(room: number) {
		return;
	},
	addInfant(room: number) {
		return;
	},
	removeInfant(room: number) {
		return;
	},
	addRoom() {
		return;
	},
	removeRoom(room: number) {
		return;
	},
	updateCurrency(currency: Currency) {
		return;
	},
	updateLocale(locale: LocaleType) {
		return;
	},
	addAddon(addon: Addon) {
		return;
	},
	createBooking(booking: CreateBooking) {
		return;
	},
	completeBooking(booking: CheckoutBooking) {
		return;
	},
	initState(state: GlobalState) {
		return;
	},
	toggleSelectedHotel(hotelId: number) {
		return;
	},
	updatePromoCode(promoCode: string) {
		return;
	},
});

export const LS_KEY = 'KEAHOTELS';
const saveToLocalStorage = (_: Action, state: GlobalState) =>
	localStorage.setItem(LS_KEY, JSON.stringify(state));

export function useCreateGlobalState() {
	const [state, dispatch] = useReducerWithMiddleware<GlobalState, Action>(
		reducer,
		initialState,
		[],
		[saveToLocalStorage],
	);

	const updateDates = useCallback(
		(dates: Partial<DateRange>) => dispatch(uDates(dates)),
		[],
	);
	const setRooms = useCallback(
		(rooms: GuestCount[]) => dispatch(sRooms(rooms)),
		[],
	);
	const addAdult = useCallback((room: number) => dispatch(aAdult(room)), []);
	const removeAdult = useCallback(
		(room: number) => dispatch(rAdult(room)),
		[],
	);
	const addChild = useCallback((room: number) => dispatch(aChild(room)), []);
	const removeChild = useCallback(
		(room: number) => dispatch(rChild(room)),
		[],
	);
	const addInfant = useCallback(
		(room: number) => dispatch(aInfant(room)),
		[],
	);
	const removeInfant = useCallback(
		(room: number) => dispatch(rInfant(room)),
		[],
	);

	const addRoom = useCallback(() => dispatch(aRoom(undefined)), []);
	const removeRoom = useCallback((room: number) => dispatch(rRoom(room)), []);

	const updateCurrency = useCallback(
		(currency: Currency) => dispatch(uCurrency(currency)),
		[],
	);
	const updateLocale = useCallback(
		(locale: LocaleType) => dispatch(uLocale(locale)),
		[],
	);

	const addAddon = useCallback((addon: Addon) => dispatch(aAddon(addon)), []);

	const createBooking = useCallback((booking: CreateBooking) => {
		dispatch(cBooking(booking));
	}, []);

	const completeBooking = useCallback((booking: CheckoutBooking) => {
		dispatch(complBooking(booking));
	}, []);

	const initState = useCallback(
		(state: GlobalState) => dispatch(iState(state)),
		[],
	);

	const toggleSelectedHotel = useCallback(
		(hotelId: number) => dispatch(tHotel(hotelId)),
		[],
	);
	const updatePromoCode = useCallback(
		(promoCode: string) => dispatch(uPromoCode(promoCode)),
		[],
	);

	useLoadGlobalState({
		initState,
		updateDates,
		setRooms,
		updateCurrency,
		updatePromoCode,
	});

	const returnValue = {
		state,
		updateDates,
		setRooms,
		addAdult,
		removeAdult,
		addChild,
		removeChild,
		addInfant,
		removeInfant,
		addRoom,
		removeRoom,
		updateCurrency,
		updateLocale,
		addAddon,
		createBooking,
		initState,
		toggleSelectedHotel,
		completeBooking,
		updatePromoCode,
	};

	return returnValue;
}

export default function useGlobalState() {
	return useContext(StateContext);
}

/**
 * Load previous state from LocalStorage, if less than
 * 24h old
 */

type Query = {
	from?: string;
	to?: string;
	rooms?: string[] | string;
	currency?: string;
	hotels?: string;
	promo: string;
};

export function useLoadGlobalState({
	initState,
	updateDates,
	setRooms,
	updateCurrency,
	updatePromoCode,
}: {
	initState: (state: GlobalState) => void;
	updateDates: any;
	setRooms: any;
	updateCurrency: any;
	updatePromoCode: any;
}) {
	const { query } = useRouter();
	const [isHydrated, setIsHydrated] = useState(false);
	useEffect(() => {
		const lsData = localStorage.getItem(LS_KEY);

		const oldState: GlobalState | undefined = lsData
			? JSON.parse(lsData)
			: undefined;

		// Query params from router
		const {
			from,
			to,
			rooms: qRooms = [],
			currency,
			promo: promoCode,
		} = query as Query;

		// Rooms from router
		const rooms = new Array<string>()
			.concat(qRooms)
			.map((r) => r.split(',').map((n) => parseInt(n)))
			.map(([adults, children, infants]) => ({
				adults,
				children,
				infants,
			}));

		if (oldState && oldState.lastUpdated && !isHydrated) {
			const lastUpdated = new Date(oldState.lastUpdated);

			if (!oldState.promoCode) {
				oldState.promoCode = INITIAL_PROMO_CODE;
			}

			if (
				differenceInDays(new Date(), lastUpdated) < storeStateForDays &&
				(!oldState.dates.from ||
					// if from date is set, it must be older than current date
					new Date(oldState.dates.from) > new Date())
			) {
				initState(oldState);
			}
		}

		if (from && to) {
			// We need this disappointing way to create dates to prevent
			// timezone issues when dealing with timezones west of UTC.
			// When doing new Date('2022-01-20') the time is set as midnight
			// UTC, thus displaying the "local" date as 2022-01-20.
			// date-fns' parseISO gives us the local timezone
			updateDates({
				from: parseISO(from),
				to: parseISO(to),
			});
		}

		if (rooms && rooms.length) setRooms(rooms);

		if (currency) {
			const curr: Currency =
				Currency[currency.toUpperCase()] || Currency.EUR;
			updateCurrency(curr);
		}

		setIsHydrated(true);

		if (promoCode) updatePromoCode(promoCode);
	}, [query]);
}

export const useGetCurrency = () => {
	const { state } = useGlobalState();
	const { lang } = useTranslation('common');

	return state.currency
		? state.currency
		: lang === 'is'
		? Currency.ISK
		: Currency.EUR;
};
