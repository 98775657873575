import NextImage, { ImageProps as NextImageProps } from 'next/image';
import c from 'classnames';
import styles from '../Image/Image.module.css';
import { forwardRef, VideoHTMLAttributes } from 'react';
import HotelLogo from '../HotelLogo/HotelLogo';
import { aspectMultiplier, CommonImageProps } from '../Image/Image';

export type VideoProps = CommonImageProps &
	VideoHTMLAttributes<HTMLVideoElement>;

export default forwardRef<HTMLVideoElement, VideoProps>(function Video(
	{
		className = '',
		height,
		width,
		ctaBox,
		aspectRatio = 'square',
		hotelOverlay,
		overlaySize = 'medium',
		caption = null,
		captionPosition = 'none',
		id,
		src,
		...rest
	},
	ref,
) {
	let h = typeof height === 'string' ? parseInt(height) : height;
	let w = typeof width === 'string' ? parseInt(width) : width;

	if (h && !w) w = h / aspectMultiplier[aspectRatio];
	if (w && !h) h = w * aspectMultiplier[aspectRatio];

	return (
		<div
			className={c({
				[styles.wrap]: 1,
				[styles[aspectRatio]]: 1,
				[className]: className,
			})}
		>
			{src && (
				<video
					ref={ref}
					className={styles.video}
					// height={h}
					// width={w}
					src={src}
					{...rest}
				/>
			)}
			{hotelOverlay && (
				<div
					className={`${styles.hotelOverlay} ${
						overlaySize === 'small' ? styles.small : ''
					} blurryBackdrop`}
				>
					<HotelLogo id={hotelOverlay} />
				</div>
			)}
			{captionPosition !== 'none' && (
				<div
					className={`${styles.caption} ${styles.videoCaption} ${styles[captionPosition]} blurryBackdrop`}
				>
					{caption}
				</div>
			)}
			{ctaBox && <div className={styles.ctaBox}>{ctaBox}</div>}
		</div>
	);
});
