import { gql, useQuery } from '@apollo/client';
import useTranslation from 'next-translate/useTranslation';
import { parseISO, format, isSameMonth } from 'date-fns';
import { is } from 'date-fns/locale';

import Blocks from '../../components/Blocks/Blocks';
import HotelBookingToolbar from '../../components/HotelBookingToolbar/HotelBookingToolbar';
import PageHeader, {
	PageHeaderFragment,
} from '../../components/PageHeader/PageHeader';
import BlockBookOffer from '../../components/Blocks/BlockBookOffer';

import styles from './PageOffersItem.module.css';

import { GetPage_page_PageHotelOffersItem } from '../../queries/__generated__/GetPage';
import { useEffect, useMemo } from 'react';
import { GetPageQuery } from '../../queries/GetPage';
import {
	GetPage as GetPageType,
	GetPageVariables,
} from '../../queries/__generated__/GetPage';
import client from '../../../apollo-client';
import { useRouter } from 'next/router';
export type PageOffersPropsItem = GetPage_page_PageHotelOffersItem;

export default function PageOffersItem({
	title,
	intro,
	blocks,
	header,
	cta,
	included,
	roomTypes,
	bonSourceId,
	hideOffersToolbar,
}: PageOffersPropsItem) {
	const { t, lang } = useTranslation('common');
	const { locale = 'en', query } = useRouter();
	const querySlug = query.slug;
	const slug = Array.isArray(querySlug) ? `/${querySlug.join('/')}` : `/`;

	// useEffect(() => {
	// 	gtag('event', 'purchase', {
	// 			currency: b.currency,
	// 			transaction_id: b.bookingRef,
	// 	})
	// }, []);

	const { data: datesRes } = useQuery<GetPageType, GetPageVariables>(
		GetPageQuery,
		{
			variables: {
				slug,
				locale,
			},
			client: client,
		},
	);

	const dates = useMemo(() => {
		if (datesRes?.page?.__typename === 'PageHotelOffersItem') {
			return datesRes.page.dates || [];
		}
		return [];
	}, [datesRes]);

	return (
		<>
			<HotelBookingToolbar hideBookingSearch={!hideOffersToolbar} />

			{header && (
				<PageHeader
					{...header}
					cta={{
						title: cta.title || undefined,
						eyebrow: cta.label || undefined,
						buttonText: t('seeAvailabledates'),
						content: (
							<div className={styles.included}>
								<p className={styles.label}>{t('included')}</p>
								<ul>
									{included?.map((item, i) => (
										<li key={i}>{item}</li>
									))}
								</ul>
							</div>
						),
						onClickHandler: (e) => {
							e.preventDefault();

							const ele = document.getElementById('booking-bar');

							if (ele) {
								window.scrollTo(0, ele.offsetTop - 64);
							}
						},
					}}
					title={title || ''}
					intro={intro || undefined}
				/>
			)}

			{!hideOffersToolbar && (
				<div id="booking-bar" className={styles.bookingBar}>
					<BlockBookOffer
						roomTypes={roomTypes.map((item) => ({
							label: item.title || '',
							value: item.bonId || '',
							godoId: item.godoId || '',
						}))}
						dates={dates.map((item) => {
							const checkInDate = parseISO(item.checkIn);
							const checkOutDate = parseISO(item.checkOut);
							const locale = lang === 'is' ? is : undefined;

							return {
								id: item.id,
								label: `${format(
									checkInDate,
									isSameMonth(checkInDate, checkOutDate)
										? 'd.'
										: 'd. MMMM',
									{
										locale: locale,
									},
								)}–${format(checkOutDate, 'd. MMMM', {
									locale: locale,
								})}`,
								value: item.id,
								checkIn: checkInDate,
								checkOut: checkOutDate,
								availability: item.availability,
							};
						})}
						bonSourceId={bonSourceId}
					/>
				</div>
			)}
			<Blocks blocks={blocks} />
		</>
	);
}

export const PageOffersItemFragment = gql`
	fragment PageOffersItemFragment on WpItem {
		... on PageHotelOffersItem {
			included
			cta {
				title
				label
			}
			bonSourceId
			dates {
				id
				checkIn
				checkOut
				availability
			}
			roomTypes {
				bonId
				godoId
				title
				slug
			}
			header {
				...PageHeaderFragment
			}
			hideOffersToolbar
		}
	}
	${PageHeaderFragment}
`;
