import { ReactNode, useContext, useEffect, useRef } from 'react';
import Image from 'next/image';
import { useDialogState, Dialog, DialogDisclosure } from 'reakit/Dialog';
import { VisuallyHidden } from 'reakit/VisuallyHidden';
import { motion, useReducedMotion } from 'framer-motion';

import { Eyebrow, Grid } from '@keahotels/components';

import { ReactComponent as Hamburger } from '@keahotels/assets/icons/Hamburger.svg';
import { ReactComponent as MenuClose } from '@keahotels/assets/icons/MenuClose.svg';
import { ReactComponent as Tripadvisor } from '@keahotels/assets/icons/Tripadvisor.svg';

import { TopBar } from '../TopBar/TopBar';

import styles from './MainMenu.module.css';
import Link from '../Link/Link';
import useTranslation from 'next-translate/useTranslation';
import { SettingsContext } from '../AppLayout/AppLayout';
import HotelLogo from '../HotelLogo/HotelLogo';
import {
	GetGlobalData_hotelSettings_PageHotel_menu,
	GetGlobalData_hotelSettings_PageHotel_menu_links as MenuLink,
} from '../../hooks/__generated__/GetGlobalData';

const container = {
	show: {
		transition: {
			staggerChildren: 0.03,
		},
	},
};

const item = {
	hidden: { opacity: 0, transform: 'translateX(-80px)' },
	show: { opacity: 1, transform: 'translateX(0px)' },
};
const rmItem = {
	hidden: { opacity: 0 },
	show: { opacity: 1 },
};

const MenuItem = ({
	href,
	children,
	target,
	onClick,
}: {
	href: string;
	target?: string;
	children: ReactNode;
	onClick?: () => void;
}) => {
	const rm = useReducedMotion();
	return (
		<motion.li variants={rm ? rmItem : item} className={styles.menuItem}>
			<Link href={href} onClick={onClick} target={target}>
				{children}
			</Link>
		</motion.li>
	);
};

export type MainMenuProps = {
	menu?: GetGlobalData_hotelSettings_PageHotel_menu | null | undefined;
};

export default function MainMenu({ menu }: MainMenuProps) {
	const { t } = useTranslation('common');
	const dialog = useDialogState({ animated: 50 });
	const ref = useRef<HTMLUListElement | null>(null);

	const { hotelSettings } = useContext(SettingsContext);

	useEffect(() => {
		if (dialog.visible) {
			ref.current?.focus();
		}
	}, [dialog.visible]);

	const menuList = menu || hotelSettings?.menu;

	if (!menuList?.links?.length) return null;

	return (
		<nav aria-label="Main" className={styles.wrap}>
			<DialogDisclosure {...dialog} className={styles.menuButton}>
				<VisuallyHidden>{t('openMenu')}</VisuallyHidden>
				<Hamburger aria-hidden />
			</DialogDisclosure>
			<Dialog
				{...dialog}
				aria-label="Welcome"
				className={styles.dialog}
				// preventBodyScroll={false}
			>
				<TopBar />
				<Grid className={styles.main}>
					<div className={styles.header}>
						<Link
							href={hotelSettings?.uri || '#'}
							className={styles.logo}
						>
							<HotelLogo />
						</Link>
						<button
							className={styles.menuButton}
							onClick={dialog.hide}
						>
							<VisuallyHidden>Close Menu</VisuallyHidden>
							<MenuClose aria-hidden />
						</button>
					</div>
					<motion.ul
						variants={container}
						initial="hidden"
						animate={dialog.visible ? 'show' : 'hidden'}
						className={styles.menu}
						ref={ref}
						tabIndex={0}
					>
						{menuList.links
							.filter((l): l is MenuLink => !!l)
							.map((link) => (
								<MenuItem
									key={link.uri}
									href={link.uri}
									onClick={dialog.hide}
									target={
										link.uri[0] !== '/'
											? '_blank'
											: undefined
									}
								>
									{link.title}
								</MenuItem>
							))}
					</motion.ul>
					{/* <figure className={styles.quote}>
						<blockquote>
							Fantastic hotel. Can't wait to come back.
						</blockquote>
						<Eyebrow as="cite">
							<Tripadvisor
								aria-label="Tripadvisor logo"
								className={styles.citeLogo}
							/>
							nickhiston writes
							<br /> from United Kingdom
						</Eyebrow>
					</figure> */}
					<div className={styles.menuImageWrap}>
						{menuList.image?.url && (
							<Image
								{...menuList.image}
								src={`${process.env.NEXT_PUBLIC_IMAGE_URL}/${menuList.image.url}`}
								id={undefined}
								layout="fill"
								className={styles.menuImage}
							/>
						)}
					</div>
				</Grid>
			</Dialog>
		</nav>
	);
}
