// import styles from './BlockContent.module.css';

import { gql } from '@apollo/client';
import {
	ContentBlock,
	ContentBlockContent,
	ContentBlockImage,
	ContentBlockImagesMobile,
	CTABox,
} from '@keahotels/components';
import React from 'react';

import Image from '../../components/Image/Image';
import isNotNull from '../../utils/isNotNull';
import Link from '../Link/Link';

import {
	BlockContentFragment_BlockContent,
	BlockContentFragment_BlockContent_blocks_BlockContentImage as BCImage,
} from './__generated__/BlockContentFragment';

export type BlockContentProps = BlockContentFragment_BlockContent;

const ar = {
	LANDSCAPE: 'landscape' as 'landscape',
	PORTRAIT: 'portrait' as 'portrait',
	SQUARE: 'square' as 'square',
};

const BlockImage = ({ item }: { item: BCImage }) =>
	item.image?.url ? (
		<ContentBlockImage pullLeft={item.extendLeft}>
			<Image
				{...item.image}
				aspectRatio={item.aspectRatio ? ar[item.aspectRatio] : 'square'}
				id={undefined}
				ctaBox={
					item.cta ? (
						<Link href={item.cta.uri}>
							<CTABox
								size="medium"
								title={item.cta.title}
								ctaText={item.cta.cta || 'Book Now'}
							/>
						</Link>
					) : undefined
				}
			/>
		</ContentBlockImage>
	) : null;

export default function BlockContent({ blocks }: BlockContentProps) {
	if (!blocks) return null;

	return (
		<ContentBlock>
			{blocks.filter(isNotNull).map((item, i) =>
				item.__typename === 'BlockContentText' ? (
					<ContentBlockContent
						key={`${item.title}_${item.subtitle}`}
						title={item.title}
						eyebrow={item.subtitle}
					>
						<div
							dangerouslySetInnerHTML={{ __html: item.content }}
						/>
					</ContentBlockContent>
				) : item.image?.url ? (
					<BlockImage item={item} key={`${item.image.url}_${i}`} />
				) : null,
			)}

			<ContentBlockImagesMobile>
				{blocks
					.filter(isNotNull)
					.filter(
						(item): item is BCImage =>
							item.__typename === 'BlockContentImage',
					)
					.map((item, i) => (
						<BlockImage
							item={item}
							key={`${item.image?.url}_${i}`}
						/>
					))}
			</ContentBlockImagesMobile>
		</ContentBlock>
	);
}

export const BlockContentFragment = gql`
	fragment BlockContentFragment on BlockItem {
		... on BlockContent {
			blocks {
				... on BlockContentImage {
					aspectRatio
					extendLeft
					image {
						id
						url
						caption
						mime_type
						width
						height
					}
					cta {
						uri
						title
						cta
					}
				}
				... on BlockContentText {
					title
					subtitle
					content
				}
			}
		}
	}
`;
