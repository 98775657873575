import useTranslation from 'next-translate/useTranslation';
import { VisuallyHidden } from 'reakit/VisuallyHidden';
import { Grid, Button } from '@keahotels/components';

import { ReactComponent as KeaHotels } from '@keahotels/assets/logo/Keahotels-no-pad.svg';
// import { ReactComponent as Usd } from '@keahotels/assets/icons/USD.svg';
import { ReactComponent as Eur } from '@keahotels/assets/icons/EUR.svg';
// import { ReactComponent as CurrencySymbol } from '@keahotels/assets/icons/CurrencySymbol.svg';
import { ReactComponent as Language } from '@keahotels/assets/icons/Language.svg';
// import { ReactComponent as Help } from '@keahotels/assets/icons/Help.svg';

import styles from './TopBar.module.css';
import useGlobalState, { useGetCurrency } from '../../hooks/useGlobalState';
import Link from '../Link/Link';
import LocationMenu from '../LocationMenu/LocationMenu';
import { useSettings } from '../AppLayout/AppLayout';
import { Currency } from '../../../__generated__/globalTypes';
import { useRouter } from 'next/router';
import { UrlObject } from 'url';
import hotelData from '../../utils/hotelData';

const currencies = [
	// {
	// 	title: 'USD',
	// 	id: Currency.EUR,
	// 	Icon: Usd,
	// 	sign: '$',
	// },
	{
		title: 'EUR',
		id: Currency.EUR,
		Icon: Eur,
		sign: '€',
	},
	{
		title: 'ISK',
		id: Currency.ISK,
		Icon: () => null, // CurrencySymbol,
		sign: 'kr',
	},
];

const TopBarBookingBar = () => {
	// const dialog = useDialogState();
	return null;

	/*	<div>
			 <BookingSelect dialogController={dialog} value={'hello'}>
				24.des -&gt; 1.jan
			</BookingSelect> 
		</div>*/
};

const TopBarButton = (props: any) => (
	<Button
		className={styles.button}
		variant="clear"
		size="small"
		classNames={{ text: styles.btnText, icon: styles.btnIcon }}
		{...props}
	/>
);

const bookingSearchPathname = '/[hotel]/book';
const paymentPagePathname = '/[hotel]/book/payment';
const thankYouPagePathname = '/[hotel]/book/thank-you';

export function TopBar() {
	const { t, lang } = useTranslation('common');
	const { updateCurrency } = useGlobalState();
	const router = useRouter();

	const currency = useGetCurrency();

	const currentCurrency =
		currencies.find((c) => c.id === currency) || currencies[0];

	const CurrencyIcon = currentCurrency.Icon;

	const { hotelSettings, translations } = useSettings();
	const hotelId = hotelSettings?.originalId;

	let translationHref: string | UrlObject = '/';
	if (router.pathname === bookingSearchPathname && hotelId) {
		const localePrefix = lang === 'is' ? '' : '/is';
		translationHref = {
			pathname: `${localePrefix}${router.pathname}`,
			query: {
				...router.query,
			},
		};
	} else if (translations?.[0]?.post?.uri) {
		translationHref = translations?.[0]?.post?.uri;
	} else if (hotelSettings?.translations?.[0]?.post?.uri) {
		translationHref = hotelSettings?.translations?.[0]?.post?.uri;
	}

	const hideSwitchers =
		router.pathname === paymentPagePathname ||
		router.pathname === thankYouPagePathname;

	const doCurrencyUpdate = (newCurrency: Currency) => {
		updateCurrency(newCurrency);

		if (router.query.currency) {
			router.replace({
				pathname: router.pathname,
				query: {
					...router.query,
					currency: newCurrency,
				},
			});
		}
	};

	const handleToggleCurrency = () => {
		doCurrencyUpdate(
			currency === Currency.ISK ? Currency.EUR : Currency.ISK,
		);
	};

	return (
		<header className={styles.outerWrap}>
			<Grid className={styles.innerWrap}>
				<Link href="/" className={styles.logoWrap}>
					<VisuallyHidden>Keahotels</VisuallyHidden>
					<KeaHotels aria-hidden className={styles.logo} />
				</Link>

				<LocationMenu className={styles.locationSelect} />

				<div className={styles.aside} role="group">
					{!hideSwitchers && (
						<>
							<TopBarButton
								icon={<CurrencyIcon />}
								onClick={handleToggleCurrency}
							>
								{currentCurrency.title}
							</TopBarButton>
							<TopBarButton
								icon={<Language />}
								as={Link}
								locale={false}
								href={translationHref}
								onClick={() =>
									doCurrencyUpdate(
										lang === 'is'
											? Currency.EUR
											: Currency.ISK,
									)
								}
								aria-label={t('changeLanguage')}
							>
								<span className={styles.notMobile}>
									{lang === 'is' ? 'English' : 'Íslenska'}
								</span>
								<span className={styles.onlyMobile} aria-hidden>
									{lang === 'is' ? 'EN' : 'IS'}
								</span>
							</TopBarButton>
						</>
					)}
					{/* <TopBarButton as={Link} icon={<Help />} href="#help">
						Help
					</TopBarButton> */}
				</div>
			</Grid>
		</header>
	);
}
