import NextLink from 'next/link';
import { ComponentProps, DetailedHTMLProps, forwardRef } from 'react';

export default forwardRef<
	HTMLAnchorElement,
	ComponentProps<typeof NextLink> &
		DetailedHTMLProps<
			React.AnchorHTMLAttributes<HTMLAnchorElement>,
			HTMLAnchorElement
		>
>(function Link(
	{ href, as, replace, scroll, shallow, passHref, prefetch, locale, ...rest },
	ref,
) {
	return (
		<NextLink
			href={href}
			as={as}
			replace={replace}
			scroll={scroll}
			shallow={shallow}
			passHref={passHref}
			prefetch={prefetch}
			locale={locale}
		>
			<a {...rest} ref={ref} />
		</NextLink>
	);
});
