import { gql } from '@apollo/client';
import { Fragment } from 'react';
import dynamic from 'next/dynamic';
import {
	GetPage_page_PageHotel_blocks as BlockItem,
	GetPage_page_PageHotel_blocks_BlockTitle as BlockTitleType,
	GetPage_page_PageHotel_blocks_BlockSlider as BlockSliderType,
} from '../../queries/__generated__/GetPage';

const BlockContent = dynamic(() => import('./BlockContent'));
const BlockContentCards = dynamic(() => import('./BlockContentCards'));
const BlockRoom = dynamic(() => import('./BlockRoom'));
const BlockSlider = dynamic(() => import('./BlockSlider'));
const BlockTitle = dynamic(() => import('./BlockTitle'));
const NeighbourhoodSection = dynamic(
	() => import('../NeighbourhoodSection/NeighbourhoodSection'),
	{
		ssr: false,
	},
);

import { BlockContentFragment } from './BlockContent';
import { BlockContentCardsFragment } from './BlockContentCards';
import { BlockRoomFragment } from './BlockRoom';
import { BlockSliderFragment } from './BlockSlider';
import { BlockTitleFragment } from './BlockTitle';
import { NeighbourhoodFragment } from '../NeighbourhoodSection/NeighbourhoodSection';

// import styles from './Blocks.module.css';

const isBlock = (b: BlockItem | null): b is BlockItem => !!b;

type Section = {
	title?: BlockTitleType | BlockSliderType;
	children: BlockItem[];
};

const splitIntoSections = (blocks: BlockItem[]) => {
	const sections: Section[] = [];
	let currentSection: Section = {
		children: [],
	};
	for (const block of blocks) {
		if (block.__typename === 'BlockTitle') {
			if (currentSection.children.length) {
				sections.push(currentSection);
			}
			currentSection = {
				title: block,
				children: [],
			};
		} else if (block.__typename === 'BlockSlider') {
			if (currentSection.children.length) {
				sections.push(currentSection);
			}
			sections.push({
				title: block,
				children: [],
			});

			currentSection = {
				children: [],
			};
		} else {
			currentSection.children.push(block);
		}
	}
	if (currentSection.title || currentSection.children.length) {
		sections.push(currentSection);
	}
	return sections;
};

export type BlocksProps = {
	blocks: (BlockItem | null)[] | null;
};

export default function Blocks({ blocks }: BlocksProps) {
	if (!blocks) return null;
	const sections = splitIntoSections(blocks.filter(isBlock));
	return (
		<>
			{sections.map((section, i) => {
				const title = section.title;
				if (title?.__typename === 'BlockTitle') {
					return (
						<BlockTitle key={title.title} {...title}>
							<Blocks blocks={section.children} />
						</BlockTitle>
					);
				} else if (title && title.blocks?.length) {
					// console.log(title);
					return <BlockSlider key={title.title} {...title} />;
				}
				return (
					<Fragment key={`${section.title}--${i}`}>
						{section.children.filter(isBlock).map((item, i) => {
							switch (item.__typename) {
								case 'BlockContent_cards':
									return (
										<BlockContentCards
											key={`${item.__typename}_${i}`}
											{...item}
										/>
									);
								case 'BlockContent':
									return (
										<BlockContent
											key={`${item.__typename}_${i}`}
											{...item}
										/>
									);
								case 'BlockAccordion':
									return <p>BlockAccordion *WIP*</p>;
								case 'BlockRoom':
									return (
										<BlockRoom
											key={`${item.content?.title}_${i}`}
											{...item}
										/>
									);
								case 'BlockNeighbourhood':
									if (item.page) {
										return (
											<NeighbourhoodSection
												key={`${item.__typename}_${i}`}
												{...item.page}
											/>
										);
									}
							}
						})}
					</Fragment>
				);
			})}
		</>
	);
}

export const BlocksFragment = gql`
	fragment BlocksFragment on BlockItem {
		...BlockContentCardsFragment
		...BlockContentFragment
		...BlockTitleFragment
		...BlockRoomFragment
		...BlockSliderFragment
		... on BlockNeighbourhood {
			type
			page {
				...NeighbourhoodFragment
			}
		}
		... on BlockAccordion {
			title
			rows {
				accordionTitle
				accordionContent
			}
		}
	}
	${BlockContentCardsFragment}
	${BlockContentFragment}
	${BlockTitleFragment}
	${BlockRoomFragment}
	${BlockSliderFragment}
	${NeighbourhoodFragment}
`;
