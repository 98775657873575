import { ReactNode } from 'react';
import { gql } from '@apollo/client';
import { Grid } from '@keahotels/components';

import { HeaderCTA, HeaderCTAProps } from '../HeroHeader/HeroHeader';
import { PageHeaderFragment as PageHeaderFragmentTypes } from './__generated__/PageHeaderFragment';

import Image from '../../components/Image/Image';

import styles from './PageHeader.module.css';

type PageHeaderTypes = {
	title: ReactNode;
	intro?: string;
	cta?: HeaderCTAProps;
} & Partial<PageHeaderFragmentTypes>;
export default function PageHeader({
	image1,
	image2,
	title,
	intro,
	cta,
}: PageHeaderTypes) {
	return (
		<>
			{image1 && image2 && (
				<div className={styles.header}>
					<Grid className={styles.imgWrap}>
						{cta && <HeaderCTA {...cta} className={styles.cta} />}
						<div className={styles.imgSquare}>
							{image1 && (
								<Image
									aspectRatio="square"
									{...image1}
									id={undefined}
								/>
							)}
						</div>

						<div className={styles.imgLandscape}>
							{image2 && (
								<Image
									aspectRatio="header-landscape"
									{...image2}
									id={undefined}
								/>
							)}
						</div>
					</Grid>
				</div>
			)}

			<Grid>
				<div className={styles.intro}>
					<h1>{title}</h1>

					{intro && (
						<div
							className="text-large-body"
							dangerouslySetInnerHTML={{ __html: intro }}
						/>
					)}
				</div>
			</Grid>
		</>
	);
}

export const PageHeaderFragment = gql`
	fragment PageHeaderFragment on PageHeader {
		image1 {
			id
			url
			width
			height
			alt
		}
		image2 {
			url
			id
			width
			height
			alt
		}
	}
`;
