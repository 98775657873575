import ho from './HotelOverwrites.module.css';

type HotelData = {
	[id: number]: {
		name: string;
		slug: string;
		className: string;
		location: {
			lat: number;
			lng: number;
		};
		//https://developers.google.com/maps/documentation/places/web-service/place-id
		googlePlaceId: string;
		bonId: number;
		mapStyle: string;
	};
};

const hotelData: HotelData = {
	76: {
		name: 'Apotek Hotel',
		slug: 'apotek-hotel', // Apotek,
		className: ho.Apotek,
		location: {
			lat: 64.14745,
			lng: -21.93831,
		},
		bonId: 1173,
		googlePlaceId: 'ChIJWZ5TQC0L1kgR8A9NzdXkdTk',
		mapStyle: 'mapbox://styles/keahotels/ckww08e2k7kui14n3wym50tim',
	},
	78: {
		name: 'Hotel Borg',
		slug: 'hotel-borg', // HotelBorg,
		className: ho.HotelBorg,
		location: {
			lat: 64.1469557,
			lng: -21.9384522,
		},
		googlePlaceId: 'ChIJpbXFaC0L1kgRirV0_BUJaL0',
		bonId: 1094,
		mapStyle: 'mapbox://styles/keahotels/ckww08mhd475115ns2s30gwno',
	},
	80: {
		name: 'Reykjavik Lights',
		slug: 'reykjavik-lights', // ReykjavikLights,
		className: ho.ReykjavikLights,
		location: {
			lat: 64.1393051147461,
			lng: -21.881498336791992,
		},
		googlePlaceId: 'ChIJF12rqr901kgRr-rNAybiMbI',
		bonId: 1172,
		mapStyle: 'mapbox://styles/keahotels/ckww0aidg39uv14nspsgk1fld',
	},
	86: {
		name: 'Hotel Kea',
		slug: 'hotel-kea', // HotelKEA,
		className: ho.HotelKEA,
		location: {
			lat: 65.6805576,
			lng: -18.0897903,
		},
		googlePlaceId: 'ChIJl2BkjQ6P0kgRaBTXuiTi_38',
		bonId: 870,
		mapStyle: 'mapbox://styles/keahotels/ckww09xk0fd9u15p281xc9cw1',
	},
	37: {
		name: 'Sand Hotel',
		slug: 'sand-hotel', // Sand,
		className: ho.Sand,
		location: {
			lat: 64.1451187133789,
			lng: -21.926851272583008,
		},
		googlePlaceId: 'ChIJJ_NQU8x01kgRXGZ0ZbsteG8',
		bonId: 2253,
		mapStyle: 'mapbox://styles/keahotels/ckww0b2en7kxn14n3r79icsam',
	},
	74: {
		name: 'Skuggi Hotel',
		slug: 'skuggi-hotel', // Skuggi,
		className: ho.Skuggi,
		location: {
			lat: 64.144348,
			lng: -21.916651,
		},
		googlePlaceId: 'ChIJlTJ7us501kgRgugMSC5M1tY',
		bonId: 1174,
		mapStyle: 'mapbox://styles/keahotels/ckwvztsjp0uur14terbha3mu0',
	},
	82: {
		name: 'Storm Hotel',
		slug: 'storm-hotel', // Storm,
		className: ho.Storm,
		location: {
			lat: 64.1446125,
			lng: -21.9105745,
		},
		googlePlaceId: 'ChIJ99pFNM901kgRCAxeSmblAf0',
		bonId: 1251,
		mapStyle: 'mapbox://styles/keahotels/ckww0aq8d9dhf14s9g0c4y3yb',
	},
	84: {
		name: 'Hotel Katla',
		slug: 'hotel-katla', // HotelKatla,
		className: ho.HotelKatla,
		location: {
			lat: 63.4266687,
			lng: -18.905439,
		},
		googlePlaceId: 'ChIJ3XeZ6ttK10gRM9e3r6DaThY',
		bonId: 2272,
		mapStyle: 'mapbox://styles/keahotels/ckww0a67y5b2m15nqsfmr7u73',
	},
	5437: {
		name: 'Siglo Hotel',
		slug: 'siglo-hotel', // Siglo,
		className: ho.HotelSiglo,
		location: {
			lat: 66.14847436773314,
			lng: -18.910635709762573,
		},
		googlePlaceId: 'ChIJg0A4CxbK0kgRjRWTW0wHSTk',
		bonId: 3530,
		mapStyle: 'mapbox://styles/keahotels/ckww0a67y5b2m15nqsfmr7u73',
	},
	5974: {
		name: 'Salt Guesthouse',
		slug: 'salt-guesthouse', // Salt,
		className: ho.SaltGuesthouse,
		location: {
			lat: 65.82626189920454,
			lng: -20.32137874655761,
		},
		googlePlaceId: 'ChIJg0A4CxbK0kgRjRWTW0wHSTk',
		bonId: 3590,
		mapStyle: 'mapbox://styles/keahotels/ckww0a67y5b2m15nqsfmr7u73',
	},
};

export default hotelData;
