import { gql } from '@apollo/client';
import c from 'classnames';
import { DialogDisclosure, useDialogState } from 'reakit/Dialog';
import useTranslation from 'next-translate/useTranslation';

import {
	Button,
	Eyebrow,
	Grid,
	IconList,
	IconListItem,
	Slide,
	Slider,
	SliderImage,
	SliderBlockRoomHeader,
	useSliderState,
} from '@keahotels/components';

import Image from '../../components/Image/Image';
import useToggle from '../../hooks/useToggle';

import { ReactComponent as Bedroom } from '@keahotels/assets/icons/Bedroom.svg';
import { ReactComponent as Camera } from '@keahotels/assets/icons/Camera.svg';
import { ReactComponent as Person } from '@keahotels/assets/icons/Person.svg';
import { ReactComponent as Room } from '@keahotels/assets/icons/Room.svg';

import stringToHashLink from '../../utils/stringToHashLink';

import styles from './BlockRoom.module.css';

import {
	BlockRoomFragment_BlockRoom,
	BlockRoomFragment_BlockRoom_content_images,
} from './__generated__/BlockRoomFragment';
import { ImageGalleryModal } from '@keahotels/components';
import isNotNull from '../../utils/isNotNull';

export type BlockRoomProps = BlockRoomFragment_BlockRoom;

export default function BlockRoom({ template, content }: BlockRoomProps) {
	const [showAllEq, toggleShowAllEq] = useToggle(false);
	const sliderControl = useSliderState();
	const dialog = useDialogState({ animated: 200 });

	const { t } = useTranslation('common');
	if (!content) return null;
	const {
		title,
		about,
		bedSize,
		equipment,
		images,
		maxPersons,
		roomSize,
		uri,
	} = content;
	const imgs = images?.filter(isNotNull);
	const isSlider = template === 'Slider';
	const extraImg = isSlider && imgs?.slice(1, 1);
	const slides = (imgs || []).map((i) => {
		return {
			id: i.url,
			background: (
				<SliderImage as={Image} {...i} aspectRatio="landscape" />
			),
			title: i.caption,
		} as Slide;
	});

	return (
		<article
			className={c({ [styles.wrap]: 1, [styles.sliderWrap]: isSlider })}
			id={stringToHashLink(title || '')}
		>
			{isSlider && (
				<Slider
					aria-label={title || ''}
					header={
						<SliderBlockRoomHeader
							title={
								<h3 id={stringToHashLink(title || '')}>
									{title}
								</h3>
							}
						>
							{maxPersons && (
								<IconListItem icon={<Person />}>
									{t('roomCard.maxPersons', {
										persons: maxPersons,
									})}
								</IconListItem>
							)}
							{bedSize && (
								<IconListItem icon={<Bedroom />}>
									{bedSize}
								</IconListItem>
							)}
							{roomSize && (
								<IconListItem icon={<Room />}>
									{t('roomCard.Avg', {
										size: roomSize,
									})}
								</IconListItem>
							)}
						</SliderBlockRoomHeader>
					}
					slides={slides}
					control={sliderControl}
					className={styles.slider}
					overlay="gradient"
				/>
			)}
			<Grid>
				<div
					className={c({
						[styles.content]: 1,
						[styles.hasSlider]: isSlider,
					})}
				>
					{!isSlider && <h3 className={styles.title}>{title}</h3>}
					{about && (
						<div
							className={styles.about}
							dangerouslySetInnerHTML={{ __html: about }}
						/>
					)}
					<div className={styles.amenities}>
						<Eyebrow as="h4" className={styles.eyebrow}>
							{t('Amenities')}
						</Eyebrow>
						<IconList>
							{bedSize && (
								<IconListItem icon={<Bedroom />}>
									{bedSize}
								</IconListItem>
							)}
							{maxPersons && (
								<IconListItem icon={<Person />}>
									{t('roomCard.maxPersons', {
										persons: maxPersons,
									})}
								</IconListItem>
							)}
							{roomSize && (
								<IconListItem icon={<Room />}>
									{t('roomCard.Avg', {
										size: roomSize,
									})}
								</IconListItem>
							)}
						</IconList>
					</div>
					<div className={styles.equipmentWrap}>
						<Eyebrow as="h4" className={styles.eyebrow}>
							{t('roomsEquippedWith')}
						</Eyebrow>
						<ul className={styles.equipment}>
							{equipment
								?.filter((_, i) => showAllEq || i < 5)
								.map((e) => (
									<li key={e.title}>{e.title}</li>
								))}

							{equipment && equipment.length > 5 ? (
								<li>
									<Button
										variant="clear"
										onClick={toggleShowAllEq}
										className={styles.phillipSeeMoreButton}
									>
										{showAllEq
											? t('showLess')
											: t('showMore')}
									</Button>
								</li>
							) : null}
						</ul>
					</div>
					{/* <Button
						icon={<NorthEast />}
						className={styles.bookNow}
						size="large"
					>
						{t('bookFromAmount', {
							amount: '€200'
						})}
						
					</Button> */}
				</div>
				<DialogDisclosure className={styles.images} {...dialog}>
					{imgs?.[0] ? (
						<Image
							{...imgs[0]}
							height={656}
							width={656}
							aspectRatio="square"
							captionPosition={
								imgs.length > 1 ? 'inside' : 'none'
							}
							caption={
								<Button
									variant="clear"
									size="small"
									as="div"
									style={{ padding: 0 }}
									icon={<Camera />}
								>
									{t('SeeAllPhotos')}
								</Button>
							}
						/>
					) : null}
				</DialogDisclosure>
				<ImageGalleryModal
					dialogState={dialog}
					images={
						imgs?.map((img, i) => (
							<div key={i}>
								<Image
									{...img}
									aspectRatio="landscape"
									// captionPosition="outside"
								/>
								{img.caption && (
									<Button
										as="p"
										size="small"
										variant="clear"
										icon={<Camera />}
									>
										{img.caption}
									</Button>
								)}
							</div>
						)) || null
					}
				/>
			</Grid>
		</article>
	);
}

export const BlockRoomFragment = gql`
	fragment BlockRoomFragment on BlockItem {
		... on BlockRoom {
			template
			content {
				title
				uri
				intro
				bedSize
				about
				equipment {
					title
				}
				maxPersons
				roomSize
				images {
					...ImageFragment
				}
			}
		}
	}
`;
