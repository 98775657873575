import React, { Fragment, ReactNode } from 'react';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import { is } from 'date-fns/locale';
import useTranslation from 'next-translate/useTranslation';

import {
	Aside,
	BreakdownList,
	BreakdownListItem,
	Eyebrow,
	Section,
	SectionHeading,
} from '@keahotels/components';

import Image from '../Image/Image';

import styles from './BookingAside.module.css';
import useGlobalState from '../../hooks/useGlobalState';
import { useSettings } from '../AppLayout/AppLayout';
import useFormatCurrency from '../../hooks/useFormatCurrency';
import { Currency } from '../../../__generated__/globalTypes';
export type BookingAsideProps = { children?: ReactNode };

export default function BookingAside({ children }: BookingAsideProps) {
	const { t, lang } = useTranslation('common');
	const { hotelSettings } = useSettings();
	const { state } = useGlobalState();
	const formatter = useFormatCurrency({
		currency: state.currentBooking?.currency || Currency.EUR,
	});

	const rooms = state.currentBooking?.rooms || [];

	const formatDate = (date: string | null) => {
		const parsedDate = parseISO(date || '');

		// console.log(state.currentBooking);

		// console.log(date, parsedDate);

		return (
			date &&
			parsedDate &&
			format(parsedDate, 'PPP', {
				locale: lang === 'is' ? is : undefined,
			})
		);
	};
	return (
		<Aside>
			<Section
				heading={<SectionHeading as="div">&nbsp;</SectionHeading>}
				className={styles.wrap}
			>
				{/* <HotelLogo id={state.currentBooking.ho} */}
				{/* <Eyebrow as="h2" className={styles.title}>
					{t('yourBooking')}
				</Eyebrow> */}
				<h2 className={`${styles.hotelName} h4`}>
					{hotelSettings?.title}
				</h2>

				<dl className={styles.checkInOut}>
					<div className={styles.checkInOutItem}>
						<Eyebrow as="dt">{t('check-in')}</Eyebrow>
						{state.currentBooking?.checkIn ? (
							<dd>
								{formatDate(
									state.currentBooking?.checkIn || null,
								)}
							</dd>
						) : null}
						{/* <dd>{hotelSettings?.checkIn}</dd> */}
					</div>
					<div className={styles.checkInOutItem}>
						<Eyebrow as="dt">{t('check-out')}</Eyebrow>
						{state.currentBooking?.checkOut ? (
							<dd>
								{formatDate(
									state.currentBooking?.checkOut || null,
								)}
							</dd>
						) : null}
						{/* <dd>{hotelSettings?.checkOut}</dd> */}
					</div>
				</dl>
				<hr className={styles.fullWidth} />
				<div>
					<Eyebrow
						as="h3"
						style={{
							marginBottom: 8,
						}}
					>
						{t('rooms')}
					</Eyebrow>
					{rooms.map(
						({ title, adults, children, infants, rate }, i) => {
							return (
								<Fragment key={i}>
									<BreakdownList style={{ marginBottom: 0 }}>
										<BreakdownListItem
											// @ts-ignore
											title={<b>{title}</b>}
											value={`${adults} ${t(
												'adultsValuePlural',
											)}${
												children
													? `, ${children} ${t(
															'children',
													  )}`
													: ''
											}${
												infants && infants === 1
													? `, ${t('infants', {
															count: infants,
													  })}`
													: infants && infants > 1
													? `, ${t('infants_multi', {
															count: infants,
													  })}`
													: ''
											}`}
										/>

										{rate.description && (
											<BreakdownListItem
												// @ts-ignore
												title={
													<span
														dangerouslySetInnerHTML={{
															__html: rate.description,
														}}
													/>
												}
												value={formatter.format(
													rate.price +
														(rate?.discount
															?.amount || 0),
												)}
												indent
											/>
										)}
										{rate.discount?.description && (
											<BreakdownListItem
												// @ts-ignore
												title={
													<span
														dangerouslySetInnerHTML={{
															__html: rate
																.discount
																.description,
														}}
													/>
												}
												value={formatter.format(
													-1 * rate.discount.amount,
												)}
												indent
											/>
										)}

										{/* <BreakdownListItem
									// @ts-ignore
									title={
										<VisuallyHidden>
											Total for this room
										</VisuallyHidden>
									}
									value={formatter.format(room.rate.price)}
									emphasis
								/> */}
									</BreakdownList>
								</Fragment>
							);
						},
					)}
				</div>
				{/*<div>
					 <Eyebrow
						as="h3"
						style={{
							marginBottom: 8,
							// color: 'var(--color-text-secondary)',
						}}
					>
						Addons
					</Eyebrow>
					<BreakdownList style={{ marginBottom: 0 }}>
						<BreakdownListItem
							title="Champagne upon arrival"
							value={formatter.format(45)}
						/>
						{state.currentBooking?.addons?.map((addon) => (
							<BreakdownListItem
								title={addon?.title || ''}
								value=""
							/>
						))}
					</BreakdownList> 
				</div>*/}
				{state.currentBooking?.totalAmount ? (
					<div>
						<BreakdownList>
							<BreakdownListItem
								title={t('totalPrice')}
								value={formatter.format(
									state.currentBooking?.totalAmount,
								)}
								emphasis
							/>
							<BreakdownListItem
								title={t('paymentDue')}
								value={formatter.format(
									state.currentBooking?.deposit || 0,
								)}
								gap
								emphasis
							/>
						</BreakdownList>
					</div>
				) : null}

				{children}

				{state.currentBooking?.rooms?.length &&
				state.currentBooking.rooms[0]?.rate?.policies?.cancelation ? (
					<p
						className={styles.cancelation}
						dangerouslySetInnerHTML={{
							__html: state.currentBooking.rooms[0]?.rate
								?.policies?.cancelation,
						}}
					></p>
				) : null}
			</Section>
		</Aside>
	);
}
