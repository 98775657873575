import { gql, useLazyQuery } from '@apollo/client';
import client from '../../apollo-client';
import { ImageFragment } from '../components/Image/Image';
import { MainHeaderFragment } from '../components/MainHeader/MainHeader';
import { TranslationFragment } from '../queries/GetPage';
import {
	GetHotelSettings,
	GetHotelSettingsVariables,
} from './__generated__/GetHotelSettings';

export const HotelSettingsFragment = gql`
	fragment HotelSettingsFragment on WpItem {
		... on PageHotel {
			title
			godoId
			bonId
			originalId
			intro
			uri
			checkIn
			checkOut
			address
			email
			facebookUrl
			phone
			fax
			instagramUrl
			reg
			tripAdvisorUrl
			twitterUrl
			stars
			fallbackHotels {
				title
				eyebrow
				hotel {
					slug
				}
			}
			translations {
				...TranslationFragment
			}
			header {
				...MainHeaderFragment
			}
			menu {
				image {
					...ImageFragment
				}
				links {
					title
					uri
				}
			}
		}
	}
	${TranslationFragment}
	${MainHeaderFragment}
	${ImageFragment}
`;
const GetHotelSettingsQuery = gql`
	query GetHotelSettings($hotel: String!, $locale: String!) {
		hotelSettings: WpItem(slug: $hotel, locale: $locale) {
			...HotelSettingsFragment
		}
	}
	${HotelSettingsFragment}
`;
export default function useHotelSettings(variables: GetHotelSettingsVariables) {
	return useLazyQuery<GetHotelSettings, GetHotelSettingsVariables>(
		GetHotelSettingsQuery,
		{
			variables,
			client,
		},
	);
}
