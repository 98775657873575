import { gql } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import c from 'classnames';
import { InteractiveMap, Marker, WebMercatorViewport } from 'react-map-gl';

import {
	Button,
	Card,
	Grid,
	Section,
	SectionHeading,
	useDimensions,
} from '@keahotels/components';
import useTranslation from 'next-translate/useTranslation';

import { ReactComponent as ExternalLink } from '@keahotels/assets/icons/ExternalLink.svg';
import { ReactComponent as HotelLocation } from '@keahotels/assets/icons/HotelLocation.svg';
import { ReactComponent as PlaceLocation } from '@keahotels/assets/icons/PlaceLocation.svg';

import styles from './NeighbourhoodSection.module.css';

import { NeighbourhoodFragment_PageHotelNeighbourhood } from './__generated__/NeighbourhoodFragment';
import { useSettings } from '../AppLayout/AppLayout';
import hotelData from '../../utils/hotelData';
import isNotNull from '../../utils/isNotNull';
import Image from '../../components/Image/Image';

const HotelMarker = ({
	location,
	title,
}: {
	location: { lat: number; lng: number };
	title: string;
}) => (
	<Marker
		longitude={location.lng}
		latitude={location.lat}
		offsetTop={-70}
		offsetLeft={-12}
	>
		<span className={styles.hotelMarkerText}>{title}</span>
		<HotelLocation className={styles.markerHotel} />
	</Marker>
);

const PlaceMarker = ({
	location,
	title,
}: {
	location: { lat: number; lng: number };
	title: string;
}) => (
	<Marker
		longitude={location.lng}
		latitude={location.lat}
		offsetTop={-15}
		offsetLeft={0}
	>
		<PlaceLocation />
		<span className={styles.placeMarkerText}>{title}</span>
	</Marker>
);

const BoundsMarkers = ({
	bounds,
}: {
	bounds: [[number, number], [number, number]];
}) => (
	<>
		<Marker latitude={bounds[0][1]} longitude={bounds[0][0]}>
			<div
				style={{
					width: 10,
					height: 10,
					background: 'black',
				}}
			></div>
		</Marker>
		<Marker latitude={bounds[1][1]} longitude={bounds[0][0]}>
			<div
				style={{
					width: 10,
					height: 10,
					background: 'black',
				}}
			></div>
		</Marker>
		<Marker latitude={bounds[1][1]} longitude={bounds[1][0]}>
			<div
				style={{
					width: 10,
					height: 10,
					background: 'black',
				}}
			></div>
		</Marker>
		<Marker latitude={bounds[0][1]} longitude={bounds[1][0]}>
			<div
				style={{
					width: 10,
					height: 10,
					background: 'black',
				}}
			></div>
		</Marker>
	</>
);

export type NeighbourhoodSectionProps = {
	title: string | null;
	intro: string | null;
} & NeighbourhoodFragment_PageHotelNeighbourhood;

export default function NeighbourhoodSection({
	title,
	intro,
	places,
	sectionTitle,
}: NeighbourhoodSectionProps) {
	const { t } = useTranslation('common');
	const { hotelSettings } = useSettings();
	const { mapStyle, location } = hotelData[hotelSettings?.originalId || 37];

	const [mapContentRef, mapContentDimensions] =
		useDimensions<HTMLDivElement>();
	const [wrapperRef, wrapperDimensions] = useDimensions<HTMLDivElement>();
	const { width, height } = mapContentDimensions || {
		width: 800,
		height: 400,
	};

	const allLocations = useMemo(
		() =>
			[location].concat(
				...(places || [])
					?.filter(isNotNull)
					.map((p) => p.locations?.filter(isNotNull) || []),
			),
		[location, places],
	);
	const bounds = useMemo(
		() => [
			[
				Math.max(...allLocations.map((l) => l.lng)),
				Math.max(...allLocations.map((l) => l.lat)),
			],
			[
				Math.min(...allLocations.map((l) => l.lng)),
				Math.min(...allLocations.map((l) => l.lat)),
			],
		],
		[allLocations],
	) as [[number, number], [number, number]];

	const initialViewport = new WebMercatorViewport({
		width,
		height,
	}).fitBounds(bounds, {
		maxZoom: 17,
	});

	const [viewport, setViewport] = useState(initialViewport);
	const [isMapVisible, setIsMapVisible] = useState(false);

	useEffect(() => {
		if (width !== viewport.width || height !== viewport.height) {
			// console.log({ wrapperDimensions, wrapperRef });
			const vw = window?.innerWidth || 100;
			const padding = vw > 1100 ? 80 : Math.min(vw / 2 - 4, 30);
			const left = vw > 1100 ? width / 2 + padding : padding;

			// console.log({ vw, padding, left, width, height });

			const updatedViewport = new WebMercatorViewport({
				width: Math.min(width, vw),
				height: Math.max(height, 500),
			}).fitBounds(bounds, {
				maxZoom: 17,
				padding: {
					top: padding,
					right: padding,
					bottom: padding,
					left,
				},
			});
			// console.log('updating viewport ', updatedViewport);

			setViewport(updatedViewport);
			setIsMapVisible(true);
		}
	}, [width, height, bounds, viewport.width, viewport.height]);

	const markers = useMemo(
		() => [
			<HotelMarker
				location={location}
				title={hotelSettings?.title || ''}
				key={'marker'}
			/>,
			...(places || []).map(({ locations, title }) => {
				const loc = locations?.filter(isNotNull);
				return loc?.length ? (
					<PlaceMarker location={loc[0]} title={title || ''} />
				) : null;
			}),
		],
		[hotelSettings?.title, location, places],
	);

	const token = process.env.NEXT_PUBLIC_MAPBOX_TOKEN;

	if (!token) console.error('Mapbox token missing.');

	const debug = false;

	return (
		<Section
			heading={<SectionHeading>{title}</SectionHeading>}
			className={styles.section}
			ref={wrapperRef}
		>
			<Grid className={styles.grid}>
				<h3 className={styles.title}>{sectionTitle}</h3>
				{intro && (
					<div
						className={styles.intro}
						dangerouslySetInnerHTML={{ __html: intro }}
					/>
				)}
				{places?.map((place) => (
					<Card
						key={place.id}
						footer={
							place.website ? (
								<Button
									icon={<ExternalLink />}
									variant="clear"
									as="a"
									target="_blank"
									href={place.website}
								>
									{t('visitWebsite')}
								</Button>
							) : null
						}
						image={
							place.header && (
								<Image
									aspectRatio="landscape"
									{...place.header}
								/>
							)
						}
						className={styles.card}
					>
						<h4 className={styles.placeTitle}>{place.title}</h4>
						{place.intro ? (
							<div
								dangerouslySetInnerHTML={{
									__html: place.intro,
								}}
							/>
						) : null}
					</Card>
				))}
				<div
					className={c({
						[styles.mapWrap]: 1,
						[styles.mapVisible]: isMapVisible,
					})}
				>
					<div className={styles.mapContent} ref={mapContentRef}>
						{token && isMapVisible && (
							<InteractiveMap
								{...viewport}
								mapStyle={mapStyle}
								mapboxApiAccessToken={token}
								onViewportChange={setViewport}
							>
								{markers}
								{debug ? (
									<BoundsMarkers bounds={bounds} />
								) : null}
							</InteractiveMap>
						)}
					</div>
				</div>
				<div className={`${styles.contentBg} blurryBackdrop`} />
			</Grid>
		</Section>
	);
}

export const NeighbourhoodFragment = gql`
	fragment NeighbourhoodFragment on WpItem {
		... on PageHotelNeighbourhood {
			title
			sectionTitle
			intro
			places {
				id
				title
				intro
				order
				uri
				website
				locations {
					lat
					lng
				}
				header {
					...ImageFragment
				}
			}
		}
	}
`;
