import { ReactComponent as Apotek } from '@keahotels/assets/logo/Apotek.svg';
import { ReactComponent as HotelBorg } from '@keahotels/assets/logo/HotelBorg.svg';
import { ReactComponent as HotelKatla } from '@keahotels/assets/logo/HotelKatla.svg';
import { ReactComponent as HotelKEA } from '@keahotels/assets/logo/HotelKEA.svg';
import { ReactComponent as Keahotels } from '@keahotels/assets/logo/Keahotels.svg';
import { ReactComponent as ReykjavikLights } from '@keahotels/assets/logo/ReykjavikLights.svg';
import { ReactComponent as Sand } from '@keahotels/assets/logo/Sand.svg';
import { ReactComponent as Skuggi } from '@keahotels/assets/logo/Skuggi.svg';
import { ReactComponent as Storm } from '@keahotels/assets/logo/Storm.svg';
import { ReactComponent as Siglo } from '@keahotels/assets/logo/Siglo.svg';
import { ReactComponent as Salt } from '@keahotels/assets/logo/Salt.svg';
import { ReactComponent as Grimsborgir } from '@keahotels/assets/logo/Grimsborgir.svg';

import { useSettings } from '../AppLayout/AppLayout';

export type HotelLogoProps = { id?: number; className?: string };

const logos = {
	76: Apotek,
	80: ReykjavikLights,
	86: HotelKEA,
	37: Sand,
	74: Skuggi,
	82: Storm,
	84: HotelKatla,
	78: HotelBorg,
	5437: Siglo,
	5974: Salt,
	9999: Grimsborgir,
};

export default function HotelLogo({ id, className = '' }: HotelLogoProps) {
	const { hotelSettings } = useSettings();

	let Logo = Keahotels;

	if (id && logos[id]) {
		Logo = logos[id];
	} else if (hotelSettings?.originalId && logos[hotelSettings?.originalId]) {
		Logo = logos[hotelSettings?.originalId];
	}

	return <Logo className={className} />;
}
