import { useEffect, useRef } from 'react';
import { Reducer, useImmerReducer } from 'use-immer';

export default function useReducerWithMiddleware<S, A>(
	reducer: Reducer<S, A>,
	initialState: S,
	middlewareFns: ((action: A, state: S) => void)[],
	afterwareFns: ((action: A, state: S) => void)[],
) {
	const [state, dispatch] = useImmerReducer<S, A>(reducer, initialState);

	const aRef = useRef<A | null>(null);

	const dispatchWithMiddleware = (action: A) => {
		middlewareFns.forEach((middlewareFn) => middlewareFn(action, state));

		aRef.current = action;

		dispatch(action);
	};

	useEffect(() => {
		if (!aRef.current) return;

		afterwareFns.forEach(
			(afterwareFn) => aRef.current && afterwareFn(aRef.current, state),
		);

		aRef.current = null;
	}, [afterwareFns, state]);

	return [state, dispatchWithMiddleware] as [S, (action: A) => void];
}
