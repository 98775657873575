// import styles from './BlockSlider.module.css';

import { gql } from '@apollo/client';
import {
	Section,
	SectionHeading,
	SliderBlockCard,
	SliderBlockContent,
} from '@keahotels/components';
import {
	Slider,
	SliderBlockHeader,
	useSliderState,
} from '@keahotels/components';
import React, { useMemo } from 'react';

import Image from '../Image/Image';

import {
	BlockSliderFragment_BlockSlider,
	BlockSliderFragment_BlockSlider_blocks as Slide,
} from './__generated__/BlockSliderFragment';

export type BlockSliderProps = BlockSliderFragment_BlockSlider;

export default function BlockSlider({ title, blocks }: BlockSliderProps) {
	const control = useSliderState();

	const slides = useMemo(
		() =>
			blocks
				?.filter((b): b is Slide => !!b)
				.map((s, i) => ({
					id: i,
					title: s.sliderTitle || '',
					content: (
						<SliderBlockContent
							title={s.sliderTitle}
							card={
								s.sliderCta?.title &&
								s.sliderCta?.slug && (
									<SliderBlockCard<'a'>
										image={null}
										as="a"
										href={s.sliderCta?.slug || '#'}
										ctaText="Book now"
										title={s.sliderCta?.title || ''}
									/>
								)
							}
						>
							{s.sliderSummary}
						</SliderBlockContent>
					),
					background: s.sliderImage ? (
						<Image {...s.sliderImage} aspectRatio="landscape" />
					) : null,
				})) || [],
		[blocks],
	);

	return (
		<Slider
			aria-label={title || ''}
			control={control}
			as="section"
			overlay="fill"
			header={<SliderBlockHeader>{title}</SliderBlockHeader>}
			slides={slides}
		/>
	);
}

export const BlockSliderFragment = gql`
	fragment BlockSliderFragment on BlockItem {
		... on BlockSlider {
			title
			blocks {
				sliderTitle
				sliderSummary
				sliderImage {
					url
					caption
					mime_type
					width
					height
				}
				sliderCta {
					title
					slug
				}
			}
		}
	}
`;
